<template>
    <div class="body">
        <div class="body-title">
            <div class="left">
                <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/body/swap-free-cropped.png">
            </div>
            <div class="right">
                <p v-html="$t('body.title2')" class="title">
                </p>
                <p v-html="$t('body.description2_1')" class="title-desc">
                   
                </p>
            </div>

        </div>
    </div>
</template>

<script>

</script>

<style lang="less" scoped>
.body {
    // background-image: url("http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/body/swap-free.png");
    // background-size: cover;
    background-color: #fafafa;

    .body-title {
        padding-top: 67px;
        margin-right: 140px;
        // padding-right: 142px;
        // margin-left: 50%;
        display: flex;
        align-items: center;

        .left {
            max-width: 960px;
            max-height: 960px;


            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .right {
            max-width: 100%;
        }

        .title {
            font-size: 80px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            max-width:782px;
            padding-bottom: 19px;
            color: #000;
        }

        .title-desc {
            font-size: 30px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            max-width: 811px;
        }
    }
}

@media only screen and (max-width:1024px) {
    .body {

        .body-title {
            margin-right: 0px;
            display: flex;
            padding-right: 28px;
            /* margin: auto; */
            padding-left: 35px;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;

            .left {
                max-width: 100%;
                padding-bottom: 144px;

                img {
                    object-fit: contain;
                    max-width: 375px;
                    max-height: 278px;
                }
            }

            .right {
                padding-left: 35px;
                max-width: 100%;
                margin: auto;
            }

            .title {
                font-size: 40px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.13;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                max-width: 600px;
            }

            .title-desc {
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                max-width: 600px;
            }
        }
    }
}
</style>