<template>
    <div class="banner">
        <div class="logo">
            <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/DPxMU_logo_web.png">
        </div>
        <div class="banner-title">
            <p v-html="$t('banner.title')" class="title">

            </p>
            <p v-html="$t('banner.description1')" class="desc">
            </p>
        </div>
        <div class="device-mobile">
            <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/devices.png">
        </div>
        <div class="register-btn">
            <a :href="fxboLink" class="btn" v-html="$t('banner.description2')"></a>
        </div>
        <div class="icon-section">
            <div class="icon-container">
                <div class="icon">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/icon-regulators.svg">
                </div>
                <div v-html="$t('banner.icon1')" class="icon-desc">
                </div>
                <div class="icon">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/icon-247.svg">
                </div>
                <div v-html="$t('banner.icon2')" class="icon-desc">
                </div>
            </div>
            <div class="icon-container">
                <div class="icon">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/icon-latency.svg">
                </div>
                <div v-html="$t('banner.icon3')" class="icon-desc special">

                </div>
                <div class="icon">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/icon-range.svg">
                </div>
                <div v-html="$t('banner.icon4')" class="icon-desc">
                </div>
            </div>
        </div>
        <div class="icon-section-mobile">
            <div class="mobile-container">
                <div class="icon">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/icon-regulators.svg">
                </div>
                <div v-html="$t('banner.icon1')" class="icon-desc">

                </div>
            </div>
            <div class="mobile-container">
                <div class="icon">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/icon-247.svg">
                </div>
                <div v-html="$t('banner.icon2')" class="icon-desc">

                </div>
            </div>
            <div class="mobile-container">
                <div class="icon">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/icon-latency.svg">
                </div>
                <div v-html="$t('banner.icon3')" class="icon-desc ">

                </div>
            </div>
            <div class="mobile-container">
                <div class="icon">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/icon-range.svg">
                </div>
                <div v-html="$t('banner.icon4')" class="icon-desc">

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import latam from '@/assets/latam'

export default {
    inject: ['countryCode', 'fxboLink'],
    // data() {
    //     return {
    //         redirection: "https://user.dooprime.com/signup/" + this.queryCheck()
    //     }
    // },
    // computed: {
    //     getRedirectionLink() {
    //         if (latam.includes(this.countryCode)) {
    //             return "https://my.dooprime.com/" + this.queryCheck()
    //         } else {
    //             return "https://user.dooprime.com/" + this.queryCheck()
    //         }
    //     }
    // },
    // methods: {
    //     queryCheck() {
    //         const paramValue = this.$route.query.userid;
    //         if (!paramValue) return ""
    //         return paramValue
    //     }
    // },
    // mounted() {
    //     this.queryCheck()
    // },
}
</script>

<style lang="less" scoped>
.banner {
    background-image: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/banner-background-with-device.png);
    background-repeat: no-repeat;
    background-size: 1920px 100%;
    max-width: 1920px;
    // height: 1080px;
    padding-left: 136px;

    .icon-section-mobile {
        display: none;
    }

    .device-mobile {
        display: none;

        .img {}
    }

    .logo {
        width: 306px;
        height: 63px;
        padding-top: 31px;

        img {
            width: 306px;
            height: 63px;
        }
    }

    .banner-title {
        margin-top: 349px;
        // padding-right: 738px;
        // display: flex;

        .title {
            font-size: 80px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            max-width: 969px;
            padding-bottom: 24px;

        }

        .desc {
            font-size: 30px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            padding-bottom: 25px;
        }

        // .devices {
        //     position:absolute;
        //     width:40%;
        //     img{
        //         width:1140px;
        //         height:735px;
        //     }
        // }
    }


    .register-btn {
        width: 190px;
        height: 50px;
        margin-top: 25px;

        .btn {
            padding: 12px 27px 11px;
            background-color: #fff;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.96;
            letter-spacing: normal;
            text-align: center;
            color: #e51c1c;
            border-radius: 5px;
            text-decoration: none;
            white-space: nowrap;
        }
    }

    .icon-section {
        display: flex;
        flex-direction: row;
        padding-bottom: 72px;

        .icon-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding-top: 157px;

            .icon {
                height: 81px;
                width: 71px;
                margin-right: 50px;
            }

            .icon-desc {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                margin-right: 50px;
                max-width: 260px;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .banner {
        background-image: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/banner/banner-background-mobile.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-left: 0px;

        .device-mobile {
            display: flex;
            max-width: 500px;
            max-height: 500px;
            align-items: center;
            justify-content: center;
            display: flex;
            margin: auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .logo {
            margin: auto;
        }

        .banner-title {
            font-size: 50px;
            max-width: 600px;
            width: 100%;
            margin: auto;
            padding-left: 35px;
            padding-top: 48px;


            .title {
                font-size: 50px;
            }

            .desc {
                font-size: 20px;
            }
        }

        .register-btn {
            margin: auto;
            margin-top: 33px;
            margin-bottom: 74px;

            .btn {}
        }

        .icon-section {
            flex-direction: column;
            padding-left: 35px;

            .icon-container {
                padding-top: 10px;
                padding-left: 37px;

                .icon {}

                .icon-desc {}
            }
        }
    }

}

@media only screen and (max-width:503px) {

    .icon-section {
        display: none !important;
    }

    .icon-container {
        padding-top: 15px;
    }

    .icon-section-mobile {
        display: block !important;
        display: flex;
        flex-direction: column;
        margin-left: 37px;
        padding-bottom: 57px;

        .mobile-container {
            display: flex;
            flex-direction: row;

            .icon {
                width: 45px;
                height: 40px;
                margin-right: 50px;
                margin-bottom: 14px;

                img {
                    width: 45px;
                    height: 40px;
                }
            }

            .icon-desc {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                margin-right: 50px;
                max-width: 260px;
                padding-top: 8px;
            }
        }
    }
}
</style>