<template>
    <footer id="footer">
        <FooterCms />
        <!-- <FooterTop />
        <FooterMiddle />
        <FooterBottom /> -->
    </footer>
</template>

<script>
import FooterTop from '@/components/footer/FooterTop'
import FooterMiddle from '@/components/footer/FooterMiddle'
import FooterBottom from '@/components/footer/FooterBottom'
import FooterCms from "@/components/footer/Footer_cms.vue";
export default {
    name: "Footer",
    components: {
        FooterTop,
        FooterMiddle,
        FooterBottom,
        FooterCms
    }
}
</script>

<style lang="scss">
#footer {
    position: relative;
    z-index: 20;
}
</style>