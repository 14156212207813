import axios from "axios";

const api = axios.create({
  // baseURL: "/api/v1",
  // baseURL: "http://localhost:3000/api/v1/",
  // withCredentials: true,
});

api.interceptors.request.use(
  async (config) => {
    config.baseURL = await getBaseUrl();
    if (window.location.hostname !== "localhost") {
      config.baseURL = config.baseURL.replace("http://", "https://");
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      // localStorage.removeItem("user-token");
      // router.push({ name: "login" });
    } else {
      return Promise.reject(error);
    }
  }
);

function getBaseUrl() {
  let baseurl = "/api/v1";
  if (window.location.hostname === "localhost") {
    baseurl = "http://localhost:3000/api/v1/";
  }

  return Promise.resolve(baseurl);
}

export default api;
