import { createRouter, createWebHistory } from "vue-router";
import landingpage from "@/views/landingPage.vue";
import i18n from "../i18n.js";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:lang",
      name: "home",
      component: landingpage,
    },
    {
      path: "/",
      redirect: "/en",
    },
  ],
});

router.beforeEach((to, _, next) => {
  if (to.path.includes("localhost")) {
    return next();
  } else {
    i18n.global.locale.value = to.path.split("/")[1];
    // console.log(i18n.global.locale.value)

    document.documentElement.setAttribute("lang", i18n.global.locale.value);
    return next();
  }
});

export default router;
