<template>
  <router-view />

  <template v-if="isMask === true">
    <MaskComponent />
  </template>
</template>


<script>
import Mask from '@/components/Mask.vue'
import axios from 'axios'
import { computed } from 'vue'
import { getUtmPath } from "@/service/cookie";

export default {
  components: {
    MaskComponent: Mask
  },
  data() {
    return {
      countryCode: null,
      fxboLink: '',
    }
  },

  provide() {
    return {
      countryCode: computed(() => this.countryCode),
      fxboLink: computed(() => this.fxboLink)
    }
  },
  methods: {
    setBodyLang() {
      document.body.className = (`${this.$i18n.locale}`)
    },
    checkQQBrowser() {
      if (/MicroMessenger|QQBrowser/.test(navigator.userAgent)) {
        this.isMask = true;
        document.title = "请使用浏览器打开";
      }
    },
    async getCountryLocation() {
      try {
        const url = `https://frontapi.finpoints.tech`;
        const data = await axios({
          method: "GET",
          url,
        });

        if (!data) throw new Error("data not found")

        const payload = data.data.data.iso_country_code
        this.countryCode = payload
      } catch (e) {
        this.$i18n.locale = 'en'
        console.log(e);
      }

    },

    queryCheck() {
      const paramValue = this.$route.query.userid;
      if (!paramValue) return ""
      return paramValue
    },


    async redirectFxbo() {
      try {
        const search = getUtmPath() || '';

        let utmUrlPath = '';
        const source = window.location.href.split('/')[2].split('.')[1] || 'website';
        if (search != '') {
          for (const [key, value] of Object.entries(search)) {
            utmUrlPath += `&${key}=${value}`;
          }
        }

        const url = `https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/new_fxbo_country_list.json`;
        const { data } = await axios({
          method: "GET",
          url,
        });


        if (data.data.hasOwnProperty('include') && data.data.include.includes(this.countryCode) || data.data.hasOwnProperty('exclude') && !data.data.exclude.includes(this.countryCode)) {
          this.fxboLink = "https://my.dooprime.com/" + this.queryCheck() + `?website=website${utmUrlPath}`
        } else {
          this.fxboLink = "https://user.dooprime.com/" + this.queryCheck() + `?website=website${utmUrlPath}`
        }

      } catch (error) {
        console.log(error);
      }

    },
  },
  async created() {
    this.setBodyLang()
    this.checkQQBrowser()
    await this.getCountryLocation()
    this.redirectFxbo()
  },


  watch: {
    $route() {
      this.setBodyLang();
    }
  },
}
</script>

<style lang="less">
* {
  margin: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
  max-width: 1920px;
  margin: auto;
}

#html {
  overflow: hidden;
  overflow-x: hidden;
}

body.en {
  * {
    font-family: SegoeUI !important;
  }
}

@font-face {
  font-family: "SegoeUI";
  src: url("@/assets/fonts/segoeui.ttf");
  font-weight: 400;
  font-style: normal;
}
</style>
