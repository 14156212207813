export default {
  "banner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eleve su potencial comercial con Doo Prime"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opere con nosotros y descubra oportunidades ilimitadas"])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate ahora"])},
    "icon1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguladores financieros internacionales"])},
    "icon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte profesional 24 horas al día, 7 días a la semana"])},
    "icon3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latencia ultrabaja"])},
    "icon4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amplia gama de productos comerciales"])}
  },
  "body": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo Multiregulado"])},
    "description1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte de un grupo con múltiples licencias internacionales, incluidas licencias regulatorias financieras de países como Vanuatu, Mauricio, el Reino Unido (FCA), Estados Unidos (SEC, FINRA)."])},
    "description1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estas licencias demuestran nuestra dedicación para cumplir con estrictos estándares de la industria y brindar a los clientes servicios seguros, confiables y de alta calidad."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas sin swap"])},
    "description2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Disfrute del trading sin swaps y de la reducción de swaps con Doo Prime! Invierta en metales preciosos, índices bursátiles y acciones estadounidenses sin preocuparse por los intereses diarios."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramientas y ventajas"])},
    "description3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copytrading"])},
    "description3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Siga, lidere y gane! Elija seguir las señales de nuestros expertos, liderar con sus estrategias o ganar dinero introduciendo. Cambie de roles fácilmente, comparta ganancias y explore información comercial detallada."])},
    "description3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecnología"])},
    "description3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceda a entornos comerciales de primer nivel con una sólida base tecnológica, que permitan una penetración fluida en los mercados financieros globales. Utilice herramientas de analíticas avanzadas, como Trading Central, para descubrir más oportunidades de inversión."])},
    "description3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plataformas"])},
    "description3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofrecemos una gama de plataformas comerciales confiables y reconocidas internacionalmente, incluidas MetaTrader 4, MetaTrader 5 y Doo Prime InTrade. Todo disponible para varios dispositivos, como ordenador, web, iOS y Android, brindando experiencias comerciales mejoradas para nuestros usuarios."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás listo para unirte a un Broker en el que puedes confiar?"])},
    "description4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete ahora"])}
  },
  "footer": {
    "contact": {
      "phoneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contactos::"])},
      "phoneEurope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+44 11 3733 5199 (Europa)"])},
      "phoneAsiaMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 3704 4241 (Asia)"])},
      "phoneAsia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+66 020260620 (Asia – Tailandia)"])},
      "phoneSingapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1415 (Asia - Singapur)"])},
      "phoneChina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+86 400 8427 539 (Asia - Región de China)"])},
      "phoneVietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+84 28 4458 2674 (Asia - Vietnam)"])},
      "phoneKorea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+82 0234837246 (Asia – Corea)"])},
      "emailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico:"])},
      "emailAccountManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es.sales", "@", "dooprime.com (Gerente de cuentas)"])},
      "emailTechnicalSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es.support", "@", "dooprime.com (Apoyo técnico)"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.facebook.com/DooPrime/"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.instagram.com/dooprime/"])},
      "linkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGv8ApVYNNOdwAAAXzj1XZwLGl13ZbnzGUihzUOn48chZef_12RQo0ClAQOSm8OMVcKrjF6Wocn811XJ-2Ue--xfddrLZuMOPbzwSyfbA3hWMaSC_pRHI_r2rXwLnxk9fApfv0=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fdoo-prime-limited"])},
      "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://twitter.com/DooPrime"])}
    },
    "compliance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgación de riesgos"])},
      "detection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["(Hemos detectado que puede estar accediendo a este sitio web desde una jurisdicción: ", _interpolate(_list(0)), " donde no brindamos servicios. Sus derechos legales no estarán protegidos por las leyes y reglamentos de su jurisdicción. Se regirá por las leyes de 'the Republic of Vanuatu', y por la presente reconoce en cumplir con las leyes de 'the Republic of Vanuatu'.)"])},
      "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lea atentamente la siguiente Declaración de riesgos"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is owned and operated by Doo Prime Vanuatu Limited and Doo Prime Limited."])},
      "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited es una empresa con domicilio social en Govant Building, Po Box 1276, Port Vila, Vanuatu y el número de registro de la empresa es 700238. Doo Prime Vanuatu Limited es un distribuidor financiero autorizado, autorizado y regulado por Vanuatu Financial Services Comisión (VU FSC), y el número reglamentario es 700238."])},
      "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Limited es una empresa con domicilio social en Fifth Floor, Zephyr House, 122 Mary Street, George Town, P.O. Box 31493, Grand Cayman KY1-1206, Islas Caimán y el número de registro de la empresa es SI-334856."])},
      "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se puede acceder al sitio web globalmente y no es específico para ningún cliente en ninguna jurisdicción. Esto se debe principalmente a la comodidad de la visualización centralizada y la comparación de información. Sus derechos y obligaciones reales se determinarán en función de la entidad y la jurisdicción que elija para ser regulada."])},
      "description6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe tener al menos 18 años antes de poder acceder a los productos y servicios de nuestro sitio web. Al acceder a nuestro sitio web, usted confirma que es una persona mayor de edad y capacidad."])},
      "description7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es posible que existan leyes y reglamentos locales que prohíban o limiten sus derechos de acceso, descarga, distribución, difusión, intercambio o uso de cualquier forma o todos los documentos e información publicados en este sitio web."])}
    },
    "tab": {
      "productsRiskDisclosureLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divulgación de riesgos de productos comerciales de alto riesgo"])},
      "productsRiskDisclosureItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los valores, futuros, CFD y otros productos financieros implican altos riesgos debido a la fluctuación en el valor y los precios de los instrumentos financieros subyacentes. Debido a los movimientos adversos e impredecibles del mercado, se podrían producir grandes pérdidas que excedan su inversión inicial en un corto período de tiempo."])},
      "productsRiskDisclosureItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desempeño pasado de una inversión no es una indicación de su desempeño en el futuro. Lea los documentos de cumplimiento disponibles en nuestro sitio web."])},
      "productsRiskDisclosureItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de comprender completamente los riesgos de operar con el instrumento financiero respectivo antes de realizar cualquier transacción con nosotros. Debe buscar asesoramiento profesional independiente si no comprende los riesgos que se explican en este documento."])},
      "brandAndEntityNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso de marca y entidad"])},
      "brandAndEntityNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime, Doo Financial, Peter Elish, Doo Clearing, Doo Tech, Doo Consulting y Doo Exchange son nombres comerciales, marcas comerciales y propiedad intelectual de Doo Group."])},
      "brandAndEntityNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ofrecemos nuestros servicios a personas o empresas de determinadas jurisdicciones, incluidas, entre otras, Afganistán, Bosnia y Herzegovina, Burundi, Canadá, República Centroafricana, Chad, Congo (Brazzaville), Congo (Kinshasa), Cuba, Chipre, Guinea Ecuatorial, Eritrea, Etiopía, Guinea, Guinea-Bissau, Guyana, Haití, Hong Kong, Indonesia, Irán, Irak, Israel, Líbano, Liberia, Libia, Malasia, Malí, Nicaragua, Corea del Norte, Rusia, Sierra Leona, Singapur, Somalia, Sudán del Sur, Sri Lanka, Sudán, Siria, Tayikistán, Trinidad y Tobago, Túnez, Turkmenistán, Emiratos Árabes Unidos, Estados Unidos, Venezuela, Yemen, Zimbabue. La información y los servicios en nuestro sitio web no son aplicables y no se proporcionarán a países o jurisdicciones donde dicha distribución de información y servicios sea contraria a los estatutos y reglamentos locales respectivos. Los visitantes de las regiones anteriores deben confirmar si su decisión de invertir en nuestros servicios está de acuerdo con los estatutos y reglamentos de su país o jurisdicción antes de utilizar nuestros servicios. Nos reservamos el derecho de modificar, modificar o descontinuar nuestros productos y servicios en cualquier momento."])},
      "clientNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso al cliente"])},
      "clientNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los símbolos comerciales que se muestran en este documento son solo para fines ilustrativos y no constituirán ningún consejo o recomendación de nuestra parte. Todos los comentarios, declaraciones, datos, información, material o material de terceros (\"Material\") proporcionados en este sitio web son solo para fines de referencia. El Material se utiliza únicamente con fines de comunicación de marketing y no contiene, ni se interpretará como un consejo de inversión y/o una recomendación de inversión para ninguna transacción. Si bien hicimos todos los esfuerzos razonables para garantizar la precisión y la integridad de la información, no hacemos representaciones ni garantías con respecto al Material y no seremos responsables de ninguna pérdida, incluida, entre otras, la pérdida de ganancias, pérdidas directas o indirectas o daños por cualquier inexactitud e incompletitud de la información proporcionada. Solo deberá usar el Material para uso personal y no podrá reproducir, copiar, redistribuir y/o licenciar el Material sin nuestro consentimiento."])},
      "clientNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usamos cookies en nuestros sitios web para personalizar la información y la experiencia que se muestra en nuestro sitio web de acuerdo con sus preferencias. Al acceder a este sitio web, usted reconoce que ha leído y acepta los detalles anteriores y acepta nuestro uso de cookies."])},
      "clientNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumplimos estrictamente con todas las leyes y regulaciones aplicables en las jurisdicciones. Es su responsabilidad determinar y asegurarse de que su inversión cumpla con sus requisitos. Usted se compromete a asumir todas las consecuencias de sus inversiones y actividades comerciales."])},
      "dooEntitiesNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Entities Notice"])},
      "dooEntitiesAddress1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc"])},
      "dooEntitiesAddress1Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una sociedad limitada con domicilio social en 5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, Estados Unidos."])},
      "dooEntitiesAddress1Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una casa de bolsa registrada en la Comisión de Bolsa y Valores de EE. UU. (US SEC) y la Autoridad Reguladora de la Industria Financiera (US FINRA) con el número de regulación SEC: 8-41551 y CRD: 24409 respectivamente."])},
      "dooEntitiesAddress1Item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peter Elish Investments Securities es un nombre comercial autorizado por Elish & Elish Inc"])},
      "dooEntitiesAddress2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited"])},
      "dooEntitiesAddress2Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una sociedad limitada con domicilio social en Berkeley Suite, 35 Berkeley Square, Mayfair, London, England, W1J 5BF, y el número de registro de la empresa es 10684079."])},
      "dooEntitiesAddress2Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited es un corredor de divisas y futuros y proveedor de liquidez, autorizado y regulado por la Autoridad de Conducta Financiera (FCA), y el número reglamentario es 833414."])},
      "dooEntitiesAddress3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited"])},
      "dooEntitiesAddress3Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una empresa con domicilio social en Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000, y el número de empresa australiano es 100 139 820."])},
      "dooEntitiesAddress3Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited es un licenciatario de servicios financieros australiano, autorizado y regulado por la Comisión Australiana de Valores e Inversiones (ASIC), y el número reglamentario es 222650."])},
      "dooEntitiesAddress4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd"])},
      "dooEntitiesAddress4Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una empresa con domicilio social en Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000, y el número de empresa australiano es 661 717 237."])},
      "dooEntitiesAddress4Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd es un proveedor de servicios de cambio de moneda digital y remesas, autorizado y regulado por el Centro de Análisis e Informes de Transacciones de Australia (AUSTRAC), y el número de autorización es IND100812107-001 y DCE100812107-001 respectivamente."])},
      "dooEntitiesAddress5Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited"])},
      "dooEntitiesAddress5Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una empresa con domicilio social en 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong, y el número de registro de la empresa es 2169884."])},
      "dooEntitiesAddress5Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited es un operador de servicios monetarios con licencia, autorizado y regulado por el Departamento de Aduanas e Impuestos Especiales de Hong Kong (licencia de operador de servicios monetarios), y el número reglamentario es 18-06-02444."])},
      "dooEntitiesAddress6Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultoría Doo limitada"])},
      "dooEntitiesAddress6Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una compañía fiduciaria de Hong Kong registrada de conformidad con la Ordenanza de Fideicomisarios (Cap. 29, Leyes de Hong Kong) con la dirección del domicilio social en 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong, y el número de registro de la empresa es 2601567."])},
      "dooEntitiesAddress6Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited es un proveedor de servicios de sociedades o fideicomisos con licencia, autorizado y regulado por Hong Kong Registro Mercantil (Fideicomiso o Licencia de Prestador de Servicios Societarios), y el número reglamentario es TC006709."])},
      "dooEntitiesAddress7Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited"])},
      "dooEntitiesAddress7Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una sociedad de responsabilidad limitada con domicilio social en Room B11, 1st Floor Providence Complex, Providence, Mahe, Seychelles con número de registro de empresa 8427202-1."])},
      "dooEntitiesAddress7Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited es un corredor de valores autorizado y está autorizado y regulado por la Autoridad de Servicios Financieros de Seychelles (FSA) con el número reglamentario SD090."])},
      "dooEntitiesAddress8Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited"])},
      "dooEntitiesAddress8Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una empresa con domicilio social en Level 2, Lot 19, Lazenda Commercial Center Phase 3, 87000 FT Labuan, Malaysia, y el número de registro de la empresa es LL17644."])},
      "dooEntitiesAddress8Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited es un licenciatario comercial de corretaje de valores y dinero, autorizado y regulado por la Autoridad de Servicios Financieros de Labuan de Malasia (MY Labuan FSA), y el número de licencia es SL/23/0022 y MB/23/0108 respectivamente"])},
      "dooEntitiesAddress9Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauricio limitada"])},
      "dooEntitiesAddress9Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una sociedad limitada con domicilio social en Lot 15 A3, 1st Floor, Cybercity, Ebene 72201, Mauricio y el número de registro comercial es 172054"])},
      "dooEntitiesAddress9Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited es un distribuidor de inversiones con licencia, autorizado y regulado por la Comisión de Servicios Financieros de Mauricio (FSC), y el número reglamentario es C119023907."])},
      "dooEntitiesAddress10Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited"])},
      "dooEntitiesAddress10Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una sociedad de responsabilidad limitada con domicilio social en Govant Building, Po Box 1276, Port Vila, Vanuatu y el número de registro comercial es 700238."])},
      "dooEntitiesAddress10Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited es un distribuidor financiero autorizado, autorizado y regulado por la Comisión de Servicios Financieros de Vanuatu (FSC), y el número reglamentario es 700238."])}
    },
    "documents": {
      "ClientAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acuerdo del cliente"])},
      "AMLCTFPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política ALD y CFT"])},
      "ExecutionPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Ejecución"])},
      "RefundPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica de reembolso"])},
      "RiskAcknowledgement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconocimiento de riesgos"])},
      "PrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
      "WebsiteTAndC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y condiciones del sitio web"])},
      "Sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mapa del sitio"])}
    },
    "bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2023 Doo Prime. All Rights Reserved."])}
  }
}