export default {
  "banner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择 Doo Prime 发掘您的交易潜能"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共同探索无限机遇"])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即注册"])},
    "icon1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国际金融监管机构"])},
    "icon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7*24 专业客户服务"])},
    "icon3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["极速交易"])},
    "icon4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["丰富多样的交易产品"])}
  },
  "body": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多重监管"])},
    "description1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 所属集团 Doo Group 旗下的法律主体持有 10+ 监管牌照，包括美国证券交易委员会（US SEC）和金融业监管局（US FINRA）、英国金融行为监管局（UK FCA）、澳洲证券与投资委员会（ASIC）等。"])},
    "description1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 严格遵守行业准则，致力于为客户提供安全、可靠及优质的交易体验。"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免息活动"])},
    "description2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 免息活动为广大投资者提供更多优惠和便利。让客户无需担忧隔夜利息费用，从容应对市场变化。"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工具与优势"])},
    "description3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copytrading"])},
    "description3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CopyTrading 让客户实现跟单、分润的交易需求！跟随优质信号源，轻松获利，或作为推广人赚取额外推荐费。角色灵活切换，共享利润，同时探索更多交易机遇。"])},
    "description3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技术"])},
    "description3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 凭借坚实的技术基础，顶级的交易环境，布局全球金融市场。通过高级分析工具，如 Trading Central，发掘更多投资机会。"])},
    "description3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易平台"])},
    "description3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime 提供多种顶尖交易平台，包括 MetaTrader 4、MetaTrader 5 以及 Doo Prime InTrade。以上交易平台支持各类设备，如电脑客户端、网站、iOS 和 Android，为用户提供便捷高效的交易体验。"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准备好和一个可靠并值得信赖的券商合作了吗？"])},
    "description4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即加入！"])}
  },
  "footer": {
    "contact": {
      "phoneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话："])},
      "phoneEurope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+44 11 3733 5199（欧洲）"])},
      "phoneAsiaMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 3704 4241（亚洲）"])},
      "phoneAsia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+66 020260620 (亚洲 – 泰国)"])},
      "phoneSingapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1415 (亚洲 – 新加坡)"])},
      "phoneChina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+86 400 8427 539（亚洲 - 中国地区）"])},
      "phoneVietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+84 28 4458 2674 (亚洲 - 越南)"])},
      "phoneKorea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+82 0234837246 (亚洲 – 韩国)"])},
      "emailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱："])},
      "emailAccountManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cn.sales", "@", "dooprime.com（客户经理）"])},
      "emailTechnicalSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cn.support", "@", "dooprime.com（技术支持）"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.facebook.com/DooPrime/"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.instagram.com/dooprime/"])},
      "linkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGv8ApVYNNOdwAAAXzj1XZwLGl13ZbnzGUihzUOn48chZef_12RQo0ClAQOSm8OMVcKrjF6Wocn811XJ-2Ue--xfddrLZuMOPbzwSyfbA3hWMaSC_pRHI_r2rXwLnxk9fApfv0=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fdoo-prime-limited"])},
      "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://twitter.com/DooPrime"])}
    },
    "compliance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合规披露"])},
      "detection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["（我们检测到您可能来自我们不提供服务的司法管辖区： ", _interpolate(_list(0)), " 您的权利将不受您所在的司法管辖区中的法律和法规的保护。您应受 'the Republic of Vanuatu' 的法律管辖，您在此承认遵守 'the Republic of Vanuatu' 法律。）"])},
      "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请仔细阅读以下合规披露："])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is owned and operated by Doo Prime Vanuatu Limited and Doo Prime Limited."])},
      "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited 是一家持牌金融交易商，公司注册地址位于 Govant Building, Po Box 1276, Port Vila, Vanuatu , 公司注册编号为 700238。Doo Prime Vanuatu Limited 受瓦努阿图金融服务委员会（ VU FSC）授权和监管，监管编号为 700238。"])},
      "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Limited 公司注册地址为 Fifth Floor, Zephyr House, 122 Mary Street, George Town, P.O. Box 31493, Grand Cayman KY1-1206, Cayman Islands，公司注册号为 SI-334856。"])},
      "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该网站可在全球范围内访问，并不特定于任何地区的客户。这主要是为了信息集中展示和对比的便利，你的实际权利和义务将基于你所选择的实体从监管所获得的授权和监管所决定。"])},
      "description6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须至少年满18岁，然后才能访问我们的网站产品和服务。 通过访问我们的网站，您确认您符合了年龄的要求。"])},
      "description7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有些当地法律法规禁止或限制您访问，下载，分发，传播，共享或以其他方式使用本网站上发布的任何或所有文档和信息的权利。"])}
    },
    "tab": {
      "productsRiskDisclosureLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高风险交易产品风险披露"])},
      "productsRiskDisclosureItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于基础金融工具的价值和价格会有剧烈变动，股票，证券，期货，差价合约和其他金融产品交易涉及高风险，可能会在短时间内发生超过您的初始投资的大额亏损。"])},
      "productsRiskDisclosureItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去的投资表现并不代表其未来的表现。"])},
      "productsRiskDisclosureItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在与我们进行任何交易之前，请确保您完全了解使用相应金融工具进行交易的风险。 如果您不了解此处说明的风险，则应寻求独立的专业建议。"])},
      "brandAndEntityNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌和实体公告"])},
      "brandAndEntityNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime, Doo Financial, Peter Elish, Doo Clearing, Doo Tech, Doo Consulting 和Doo Exchange 是Doo Group 所拥有的商用名称、注册商标和知识产权。"])},
      "brandAndEntityNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们有各种受监管和许可的实体提供相关的金融服务。 您可以与我们的任何实体建立业务关系。 所选择的实体意味着您的交易账户，交易活动和资金只由这特定的监管机构进行监管。"])},
      "brandAndEntityNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们不向某些司法管辖区的个人和公司提供服务，包括但不限于阿富汗，波斯尼亚和黑塞哥维那，布隆迪，加拿大，中非共和国，乍得，刚果共和国，刚果民主共和国，古巴，塞浦路斯，赤道几内亚，厄立特里亚，埃塞俄比亚，几内亚，几内亚比绍，圭亚那，海地，香港，印度尼西亚，伊朗，伊拉克，以色列，黎巴嫩，利比里亚，利比亚，马来西亚，马里，尼加拉瓜，朝鲜，俄罗斯，塞拉利昂，新加坡，索马里，南苏丹，斯里兰卡，苏丹，叙利亚，塔吉克斯坦，特立尼达和多巴哥，突尼斯，土库曼斯坦，阿拉伯联合酋长国，美国，委内瑞拉，也门，津巴布韦。 我们网站上的信息和服务不适用于，并不应提供给在此类信息和服务的发布违反各自当地法律法规的国家或司法管辖区。来自上述地区的访客应在使用我们的服务之前，确认您对我们的服务进行投资的决定是否符合您居住国家/地区的法规。我们保留随时更改，修改或终止我们的产品和服务的权利。"])},
      "clientNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户须知"])},
      "clientNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此处显示的任何交易符号仅用于说明目的，不构成我们的任何建议。 本网站上提供的任何评论，陈述，数据，信息，材料或第三方材料（“材料”）仅供参考。 该材料仅被认为是市场传播，不包含，也不应被解释为包含任何交易的投资建议和/或投资推荐。 尽管我们已尽一切合理的努力确保信息的准确性和完整性，但我们对材料不做任何陈述和保证，如果所提供信息的任何不准确和不完整，我们也不对任何损失负责，包括但不限于利润损失，直接或间接损失或损害赔偿。 未经我们的同意，您只能将该材料用于个人用途，不得复制，复制，重新分发和/或许可该材料。"])},
      "clientNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们使用我们网站上的cookies来根据您的喜好自定义我们网站上显示的信息和体验。 通过访问本网站，您承认您已经阅读并同意上述详细信息，并同意我们使用cookies。"])},
      "clientNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们完全遵守司法管辖区中所有适用的法律和法规。 您有责任确定并确保您的投资符合您的要求。您承诺将承担投资和交易的所有后果。"])},
      "dooEntitiesNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo 实体公告"])},
      "dooEntitiesAddress1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc"])},
      "dooEntitiesAddress1Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States的公司。"])},
      "dooEntitiesAddress1Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc 是一家受美国证券交易委员会（US SEC）和金融业监管局 (US FINRA) 监管的经纪商，监管编号分别为 SEC: 8-41551和CRD: 24409。"])},
      "dooEntitiesAddress1Item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peter Elish Investments Securities 是Elish & Elish Inc 授权的商业名称。"])},
      "dooEntitiesAddress2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited"])},
      "dooEntitiesAddress2Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Berkeley Suite, 35 Berkeley Square, Mayfair, London, England, W1J 5BF 的公司， 公司注册编号为10684079。"])},
      "dooEntitiesAddress2Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited 是一家期货、外汇经纪商和流动性供应商，受英国金融行为监管局（UK FCA）授权和监管，监管编号为833414。"])},
      "dooEntitiesAddress3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited"])},
      "dooEntitiesAddress3Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000 的公司， 澳大利亚公司编号为100 139 820。"])},
      "dooEntitiesAddress3Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited 是持有澳大利亚金融服务牌照持的主体，受澳大利亚证券与投资委员会（ASIC）授权和监管，监管编号为222650。"])},
      "dooEntitiesAddress4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd"])},
      "dooEntitiesAddress4Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000 的公司， 澳大利亚公司编号为 661 717 237。"])},
      "dooEntitiesAddress4Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd 是汇款和数字货币兑换服务提供商，受澳大利亚交易报告和分析中心（AUSTRAC）授权和监管，授权编号分别为 IND100812107-001 和 DCE100812107-001。"])},
      "dooEntitiesAddress5Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited"])},
      "dooEntitiesAddress5Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司，公司注册编号为2169884。"])},
      "dooEntitiesAddress5Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited 是一家持牌货币服务运营商，受香港海关 （金钱服务经营者牌照） 授权和监管，监管编号为18-06-02444。"])},
      "dooEntitiesAddress6Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited"])},
      "dooEntitiesAddress6Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家根据香港法例第29章《受托人条例》注册的香港信托公司，注册地址位于 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong 的公司，公司注册编号为 2601567 。"])},
      "dooEntitiesAddress6Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited 是一家持牌信托或公司服务提供者，受香港公司注册处（信托或公司服务提供者牌照）授权和监管，监管编号为 TC006709。"])},
      "dooEntitiesAddress7Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited"])},
      "dooEntitiesAddress7Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于Room B11, 1st Floor Providence Complex, Providence, Mahe, Seychelles 的公司，公司注册编号为8427202-1。"])},
      "dooEntitiesAddress7Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited 是一家持牌证券交易商，受塞舌尔金融服务管理局（SC FSA）授权和监管，监管编号为SD090。"])},
      "dooEntitiesAddress8Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited"])},
      "dooEntitiesAddress8Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Level 2, Lot 19, Lazenda Commercial Centre Phase 3, 87000 FT Labuan, Malaysia 的公司, 公司注册编号为 LL17644。"])},
      "dooEntitiesAddress8Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited 是持有证券和金融货币经纪牌照的主体，受马来西亚纳闽金融服务管理局（MY Labuan FSA）授权和监管，监管编号分别为 SL/23/0022 和 MB/23/0108。"])},
      "dooEntitiesAddress9Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited"])},
      "dooEntitiesAddress9Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 The Cyberati Lounge, Ground Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebene, Republic of Mauritius 的公司, 公司注册编号为172054。"])},
      "dooEntitiesAddress9Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited 是一家持牌投资交易商，受毛里求斯金融服务委员会（MU FSC）授权和监管，监管编号为 C119023907。"])},
      "dooEntitiesAddress10Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited"])},
      "dooEntitiesAddress10Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Govant Building, Po Box 1276, Port Vila, Vanuatu 的公司, 公司注册编号为700238。"])},
      "dooEntitiesAddress10Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited 是一家持牌金融交易商，受瓦努阿图金融服务委员会（ VU FSC）授权和监管，监管编号为700238。"])}
    },
    "documents": {
      "ClientAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户协议"])},
      "AMLCTFPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML& CTF 政策"])},
      "ExecutionPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["执行政策"])},
      "RefundPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款政策"])},
      "RiskAcknowledgement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["风险披露声明"])},
      "PrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人隐私条例声明"])},
      "WebsiteTAndC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站条款细则"])},
      "Sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站地图"])}
    },
    "bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2023 Doo Prime。版权所有。"])}
  }
}