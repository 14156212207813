<template>
    <div class="body">
        <div class="body-title">
            <div>
                <p v-html="$t('body.title4')" class="title">

                </p>
            </div>
        </div>
        <div class="bottom">
            <div class="btn">
                <a v-html="$t('body.description4_1')" :href="fxboLink" class="join-btn">

                </a>
            </div>
        </div>
    </div>
</template>

<script>
import latam from '@/assets/latam'
export default {
    inject: ['countryCode', 'fxboLink'],
    // data() {
    //     return {
    //         redirection: "https://user.dooprime.com/signup/" + this.queryCheck()
    //     }
    // },
    // computed: {
    //     getRedirectionLink() {
    //         if (latam.includes(this.countryCode)) {
    //             return "https://my.dooprime.com/" + this.queryCheck()
    //         } else {
    //             return "https://user.dooprime.com/" + this.queryCheck()
    //         }
    //     }
    // },
    // methods: {
    //     queryCheck() {
    //         const paramValue = this.$route.query.userid;
    //         if (!paramValue) return ""
    //         return paramValue
    //     }
    // },
    // mounted() {
    //     this.queryCheck()
    // }
}
</script>

<style lang="less" scoped>
.body {
    background-image: url("http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/body/broker.png");
    background-size: cover;
    padding-left: 136px;

    .body-title {
        padding-top: 281px;
        text-align: left;

        .title {
            max-width: 700px;
            font-size: 60px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            margin-bottom: 30px;
        }

        span {
            font-weight: bold;
            color: red;
        }

    }

    .bottom {
        width: 190px;
        height: 50px;
        padding-bottom: 188px;
    }

    .btn {
        background-color: #e51c1c;
        width: 190px;
        height: 50px;
        padding: 15.5px 15px 14.5px 15px;
        border-radius: 5px;
        white-space: nowrap;

        .join-btn {
            text-decoration: none;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            white-space: nowrap;
            font-style: normal;
            line-height: 0.96;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
        }
    }
}

@media only screen and (max-width:1024px) {
    .body {
        background-image: url("http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/body/broker-mobile.png");
        background-size: cover;
        background-position: 50% 66%;
        background-repeat: no-repeat;
        padding-left: 0px;


        .bottom {
            margin: auto;
            padding-bottom: 365px;
        }

        .body-title {
            padding-top: 33px;
            padding-bottom: 30px;
            padding-right: 25px;
            text-align: center;
            margin-left: 0px;

            .title {
                font-size: 40px;
                margin: auto;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                max-width: 600px;
                width: 100%;
                padding-bottom: 41px;
            }

            span {
                font-weight: bold;
            }

            .btn {
                margin: auto;
                margin-bottom: 365px;

                .join-btn {
                    background-color: #e51c1c;
                    width: 190px;
                    height: 50px;
                    padding: 8.5px 15px 14.5px 15px;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.96;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    border-radius: 5px;
                }
            }
        }
    }
}
</style>