<template>
  <bannerSection/>
  <bodySection/>
  <footerSection/>
</template>

<script>
import bannerSection from '@/components/banner/index.vue'
import bodySection from '@/components/body/index.vue'
import footerSection from '@/components/footer/index.vue'
export default {
  components:{
    bannerSection,
    bodySection,
    footerSection,
  }
}
</script>