import api from "./config";

export const footer = async (data) => {
  try {
    let baseURL =
      "https://cms-v2-manage.finpoints.tech/api/v1/public/getFooter";
    let apiKey = " 6m7boAkl8q0U6hgJ";
    const payload = await api({
      method: "GET",
      url: baseURL,
      headers: {
        apiKey,
      },
      params: data,
      // withCredentials: true,
    });

    return payload;
  } catch (e) {
    return e;
  }
};
