<template>
    <div id="footer-middle">
        <div class="footer-middle-wrapper container">

            <div class="main-compliance">
                <h4 v-t="'footer.compliance.title'"></h4>
                <p class="detection-footer" v-html="$t('footer.compliance.detection', [country])"></p>
                <p v-t="'footer.compliance.description1'"></p>
                <p v-t="'footer.compliance.description4'"></p>
                <p v-t="'footer.compliance.description3'"></p>
                <p v-t="'footer.compliance.description5'"></p>
                <p v-t="'footer.compliance.description6'"></p>
                <p v-t="'footer.compliance.description7'"></p>
            </div>

            <div class="main-tab">
                <div class="tab-button-wrapper">
                    <div :class="`tab-button ${tabActive === key ? 'active' : ''}`" v-for="(tab, key) in tabData"
                        @click="openTab(key)" v-t="`footer.tab.${tab.title}`"></div>
                </div>
                <div class="tab-content-wrapper">
                    <div :class="`tab-content-item ${tabActive === key ? 'active' : ''}`" v-for="(tab, key) in tabData">
                        <h4 class="tab-title-mobile" v-t="`footer.tab.${tab.title}`" @click="openTab(key)"></h4>
                        <div class="content-wrapper">
                            <p v-if="tab.content" v-for="item in tab.content" v-t="`footer.tab.${item}`"></p>
                            <div v-if="tab.address" class="address-wrapper">
                                <div class="address-item" v-for="address in tab.address">
                                    <h4 v-t="`footer.tab.${address.title}`"></h4>
                                    <p v-for="content in address.content" v-t="`footer.tab.${content}`"></p>
                                    <div v-if="address.url" class="address-url-wrapper">
                                        <!-- <a v-for="url in address.url" :href="`https://${url}`">{{ url }}</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-documents">
                <a class="documents" v-for="item in documents" v-t="item.title" :href="item.url ? item.url : '#'"></a>
            </div>
        </div>
    </div>
</template>

<script>
const tabData = [
    {
        title: "productsRiskDisclosureLabel",
        content: [
            "productsRiskDisclosureItem1",
            "productsRiskDisclosureItem2",
            "productsRiskDisclosureItem3",
        ]
    },
    {
        title: "brandAndEntityNoticeLabel",
        content: [
            "brandAndEntityNoticeItem1",
            "brandAndEntityNoticeItem2",
            "brandAndEntityNoticeItem3",
        ]
    },
    {
        title: "clientNoticeLabel",
        content: [
            "clientNoticeItem1",
            "clientNoticeItem2",
            "clientNoticeItem3",
        ]
    },
    {
        title: "dooEntitiesNoticeLabel",
        address: [
            {
                title: "dooEntitiesAddress1Title",
                content: [
                    "dooEntitiesAddress1Item1",
                    "dooEntitiesAddress1Item2",
                    "dooEntitiesAddress1Item3",
                ],
                url: [
                    "www.doofinancial.com",
                    "www.elish.com",
                ]
            },
            {
                title: "dooEntitiesAddress2Title",
                content: [
                    "dooEntitiesAddress2Item1",
                    "dooEntitiesAddress2Item2",
                ],
                url: [
                    "www.dooclearing.co.uk",
                ]
            },
            {
                title: "dooEntitiesAddress3Title",
                content: [
                    "dooEntitiesAddress3Item1",
                    "dooEntitiesAddress3Item2",
                ],
                url: [
                    "www.doofinancial.com",
                ]
            },
            {
                title: "dooEntitiesAddress4Title",
                content: [
                    "dooEntitiesAddress4Item1",
                    "dooEntitiesAddress4Item2",
                ],
                url: [
                ]
            },
            {
                title: "dooEntitiesAddress5Title",
                content: [
                    "dooEntitiesAddress5Item1",
                    "dooEntitiesAddress5Item2",
                ],
                url: [
                    "www.dooexchange.com",
                ]
            },
            {
                title: "dooEntitiesAddress6Title",
                content: [
                    "dooEntitiesAddress6Item1",
                    "dooEntitiesAddress6Item2",
                ],
                url: [
                    "www.dooprime.mu",
                ]
            },
            {
                title: "dooEntitiesAddress7Title",
                content: [
                    "dooEntitiesAddress7Item1",
                    "dooEntitiesAddress7Item2",
                ]
            },
            {
                title: "dooEntitiesAddress8Title",
                content: [
                    "dooEntitiesAddress8Item1",
                    "dooEntitiesAddress8Item2",
                ],
            },
            {
                title: "dooEntitiesAddress9Title",
                content: [
                    "dooEntitiesAddress9Item1",
                    "dooEntitiesAddress9Item2",
                ],
            },
            {
                title: "dooEntitiesAddress10Title",
                content: [
                    "dooEntitiesAddress10Item1",
                    "dooEntitiesAddress10Item2",
                ],
            },

        ]
    },
]

const documents = [
    {
        title: "footer.documents.ClientAgreement",
        url: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/prime-website3/pdf/Client_Agreement_(Doo_Prime)_(2023.09.14).pdf"
    },
    {
        title: "footer.documents.AMLCTFPolicy",
        url: "https://cdn.dootech.io/prime-website3/pdf/AML%20and%20CTF%20Policy%20(Doo%20Prime%20Vanuatu%20Limited).pdf"
    },
    {
        title: "footer.documents.ExecutionPolicy",
        url: "https://cdn.dootech.io/prime-website3/pdf/Execution%20Policy%20(Doo%20Prime%20Vanuatu%20Limited).pdf"
    },
    {
        title: "footer.documents.RefundPolicy",
        url: "https://cdn.dootech.io/prime-website3/pdf/Refund%20Policy%20(Doo%20Prime).pdf"
    },
    {
        title: "footer.documents.RiskAcknowledgement",
        url: "https://cdn.dootech.io/prime-website3/pdf/Risk%20Disclosure%20And%20Acknowledgement%20(Doo%20Prime%20Vanuatu%20Limited).pdf"
    },
    {
        title: "footer.documents.PrivacyPolicy",
        url: "https://cdn.dootech.io/prime-website3/pdf/Privacy%20Policy%20(Doo%20Prime%20Vanuatu%20Limited).pdf"
    },
    {
        title: "footer.documents.WebsiteTAndC",
        url: "https://cdn.dootech.io/prime-website3/pdf/Website%20Terms%20and%20Conditions%20(Doo%20Prime%20Vanuatu%20Limited).pdf"
    },
    {
        title: "footer.documents.Sitemap"
    }
]

import axios from 'axios'
import countryList from "@/assets/countryList.json"

export default {
    name: "FooterMiddle",
    methods: {
        openTab(id) {
            if (this.tabActive === id) {
                this.tabActive = -1;
            }
            else {
                this.tabActive = id;
            }
        },
        async getCountryLang(country) {
            try {
                const url = `https://frontapi.finpoints.tech`;
                const data = await axios({
                    method: "GET",
                    url,
                });

                if (!data) throw new Error("data not found")

                const payload = data.data.data.iso_country_code
                const tester = data.data

                // const locationToLang = {
                //     HK: "tc",
                //     CN: "sc",
                //     ES: "es",
                //     CR: "es",
                //     SV: "es",
                //     GT: "es",
                //     HN: "es",
                //     MX: "es",
                //     NI: "es",
                //     PA: "es",
                //     AR: "es",
                //     BO: "es",
                //     CL: "es",
                //     CO: "es",
                //     EC: "es",
                //     GY: "es",
                //     PY: "es",
                //     PE: "es",
                //     SR: "es",
                //     UY: "es",
                //     VE: "es",
                //     CU: "es",
                //     DO: "es",
                //     HT: "es",
                //     TW: "tc",
                //     TH: "th",
                //     JP: "jp",
                //     KR: "kr",
                //     VN: "vn",
                //     PT: "pt",
                //     ID: "id",
                //     AE: "ar",
                //     SA: "ar",
                //     QA: "ar",
                //     KW: "ar",
                //     BH: "ar",
                //     OM: "ar",
                //     EG: "ar",
                //     JO: "ar",
                //     LB: "ar",
                //     IQ: "ar",
                //     PS: "ar",
                //     SY: "ar",
                //     BR: "pt",
                //     LA: "en",
                //     KH: "en",
                //     default: "en",
                // };

                // let lang = locationToLang["default"];

                const locationThatMatters = [
                    "HK",
                    "CN",
                    "ES",
                    "TW",
                    "TH",
                    "JP",
                    "KR",
                    "VN",
                    "PT",
                    "ID",
                    "AE",
                    "SA",
                    "QA",
                    "KW",
                    "BH",
                    "OM",
                    "EG",
                    "JO",
                    "LB",
                    "IQ",
                    "PS",
                    "SY",
                    "BR",
                    "LA",
                    "KH",
                    "CR",
                    "SV",
                    "GT",
                    "HN",
                    "MX",
                    "NI",
                    "PA",
                    "AR",
                    "BO",
                    "CL",
                    "CO",
                    "EC",
                    "GY",
                    "PY",
                    "PE",
                    "SR",
                    "UY",
                    "VE",
                    "CU",
                    "DO",
                    "HT",
                ];

                // if (locationThatMatters.includes(payload)) {
                //     lang = locationToLang[payload];
                // }


                this.country = payload
                // this.$i18n.locale = lang
                this.currentLocation = this.country
                // console.log(this.country,'test country')

            } catch (e) {
                this.$i18n.locale = 'en'
                this.country = 'PT'
                console.log(e);
            }

        },
        codeToFullName() {
            let countryObj = countryList
            let theMatch = false;
            for (let i = 0; i < countryObj.length; i++) {
                if (theMatch == false && countryObj[i].code === this.currentLocation) {
                    this.country = countryObj[i].name
                    theMatch = true
                    // console.log(this.country, 'status')
                }
            }
        },
    },
    data() {
        return {
            tabData: tabData,
            documents: documents,
            currentLocation: "",
            country: "",
            tabActive: 0,
        }
    },
    async mounted() {
        await this.getCountryLang()
        this.codeToFullName()
    }
}
</script>

<style lang="scss">
#footer-middle {
    padding: 40px 80px 0 80px;
    background-color: #eff1f3;
    text-align: left;
}

#footer-middle .main-compliance .detection-footer {
    color: #16a0ff;
}

#footer-middle .main-compliance h4 {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    letter-spacing: 1.5px;
    color: #000018;
    margin-bottom: 14px;
}

#footer-middle .main-compliance p {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: 1.5px;
    color: #000018;
}

#footer-middle .main-compliance p:not(:last-child) {
    margin-bottom: 16px;
}

#footer-middle .main-tab {
    margin-top: 47px;
}

#footer-middle .main-tab .tab-button-wrapper {
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
}

@media screen and (max-width: 1024px) {

    #footer-middle {
        padding: 40px 24px 40px 24px;
    }

    #footer-middle .main-tab .tab-button-wrapper {
        display: none;
    }
}

#footer-middle .main-tab .tab-button-wrapper .tab-button {
    padding: 14px 0 17px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.5px;
    color: #000018;
    margin-right: 83px;
    position: relative;
    cursor: pointer;
}

#footer-middle .main-tab .tab-button-wrapper .tab-button:hover {
    color: #ec3f33;
}

#footer-middle .main-tab .tab-button-wrapper .tab-button::after {
    content: "";
    background-color: #ec3f33;
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.5s;
}

#footer-middle .main-tab .tab-button-wrapper .tab-button.active {
    color: #ec3f33;
}

#footer-middle .main-tab .tab-button-wrapper .tab-button.active::after {
    content: "";
    width: 48px;
    transition: 0.5s;
}

#footer-middle .main-tab .tab-content-wrapper {
    padding-top: 27px;
}

#footer-middle .main-tab .tab-content-wrapper .tab-content-item {
    overflow: hidden;
}

@media screen and (max-width: 1024px) {
    #footer-middle .main-tab .tab-content-wrapper .tab-content-item {
        border-top: 1px solid #e4e4e4;
    }

    #footer-middle .main-tab .tab-content-wrapper .tab-content-item:last-child {
        border-bottom: 1px solid #e4e4e4;
    }
}

#footer-middle .main-tab .tab-content-wrapper .tab-content-item .content-wrapper {
    height: 0;
}

#footer-middle .main-tab .tab-content-wrapper .tab-content-item.active .content-wrapper {
    height: fit-content;
    transition: 0.5s;
}

#footer-middle .main-tab .tab-content-wrapper .tab-content-item.active .tab-title-mobile::after {
    content: url('@/assets/icon-common-minus.svg');
}

#footer-middle .main-tab .tab-content-wrapper .tab-content-item p {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: 1.5px;
    color: #000018;
    margin-bottom: 12px;
}

#footer-middle .main-tab .tab-content-wrapper .tab-content-item .address-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px 24px;
}

@media screen and (max-width: 1024px) {
    #footer-middle .main-tab .tab-content-wrapper .tab-content-item .address-wrapper {
        grid-template-columns: 1fr;
    }
}

#footer-middle .main-tab .tab-content-wrapper .tab-content-item .address-wrapper h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.75px;
    margin-bottom: 24px;
}

#footer-middle .main-tab .tab-content-wrapper .tab-content-item .address-wrapper p {
    margin-bottom: 16px;
}

#footer-middle .main-tab .tab-content-wrapper .tab-content-item .address-wrapper a {
    color: #d91d22;
    margin-bottom: 8px;
    display: block;
}

#footer-middle .main-tab .tab-content-wrapper .tab-content-item .tab-title-mobile {
    display: none;
    position: relative;
}

@media screen and (max-width: 1024px) {
    #footer-middle .main-tab .tab-content-wrapper .tab-content-item .tab-title-mobile {
        display: block;
        padding: 16px 24px 16px 0;
    }

    #footer-middle .main-tab .tab-content-wrapper .tab-content-item .tab-title-mobile::after {
        content: url('@/assets/icon-common-plus.svg');
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

#footer-middle .main-documents {
    margin-top: 71px;
    padding-bottom: 24px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
    #footer-middle .main-documents {
        margin-top: 40px;
    }
}

#footer-middle .main-documents a {
    padding: 0 24px;
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: 1.5px;
    color: #36383b;
    text-decoration: none;
    position: relative;
}

@media screen and (max-width: 1024px) {
    #footer-middle .main-documents a {
        padding: 0 16px;
    }
}

#footer-middle .main-documents a:not(:last-child)::after {
    position: absolute;
    content: "";
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 8px;
    background-color: #36383b;
}
</style>