<template>
    <multiRegulated/>
    <swapFree/>
    <toolsSection/>
    <joinSection/>
</template>

<script>
import multiRegulated from "./multiRegulated.vue"
import swapFree from "./swapFree.vue"
import toolsSection from "./tools.vue"
import joinSection from "./readyJoin.vue"
export default {
    components: {
        multiRegulated,
        swapFree,
        toolsSection,
        joinSection,
    }
}
</script>

<style>

</style>