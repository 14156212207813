<template>
    <div id="footer-bottom">
        <div class="footer-bottom-wrapper container">
        <p v-t="'footer.bottom'"></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterBottom"
    }
</script>

<style lang="scss">
    #footer-bottom {
        font-size: 14px;
        color: #ffffff;
        padding: 32px 24px;
        background-color: #000018;
        text-align: center;
    }
</style>