export default {
  "banner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eleve o seu potencial comercial com o Doo Prime"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opere conosco e descubra oportunidades ilimitadas"])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre-se agora"])},
    "icon1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguladores financeiros internacionais"])},
    "icon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte profissional 24 horas por dia, 7 dias por semana"])},
    "icon3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latência ultrabaixa"])},
    "icon4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ampla gama de produtos comerciais"])}
  },
  "body": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo Multirregulado"])},
    "description1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte de um grupo com múltiplas licenças internacionais, incluindo licenças regulatórias financeiras de países como Vanuatu, Maurícia, Reino Unido (FCA) e Estados Unidos (SEC, FINRA)."])},
    "description1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essas licenças demonstram nosso compromisso em cumprir rigorosos padrões da indústria e fornecer aos clientes serviços seguros, confiáveis e de alta qualidade."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas sem swap"])},
    "description2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aproveite a negociação sem swaps e a redução de swaps com a Doo Prime! Invista em metais preciosos, índices de ações e ações americanas sem se preocupar com os juros diários."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferramentas e vantagens"])},
    "description3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copytrading"])},
    "description3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siga, lidere e ganhe! Escolha seguir os sinais dos nossos especialistas, liderar com suas estratégias ou ganhar dinheiro introduzindo. Mude de funções facilmente, compartilhe lucros e explore informações comerciais detalhadas."])},
    "description3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecnologia"])},
    "description3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesse ambientes de negociação de primeira linha com uma base tecnológica sólida, permitindo uma penetração suave nos mercados financeiros globais. Utilize ferramentas de análise avançada, como Trading Central, para descobrir mais oportunidades de investimento."])},
    "description3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plataformas"])},
    "description3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferecemos uma variedade de plataformas de negociação confiáveis e internacionalmente reconhecidas, incluindo MetaTrader 4, MetaTrader 5 e Doo Prime InTrade. Todas disponíveis em várias dispositivos, como computadores, web, iOS e Android, proporcionando experiências de negociação aprimoradas para nossos usuários."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você está pronto para se juntar a uma corretora em que pode confiar?"])},
    "description4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comece agora."])}
  },
  "footer": {
    "contact": {
      "phoneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatos:"])},
      "phoneEurope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+44 11 3733 5199 (Europa)"])},
      "phoneAsiaMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 3704 4241 (Ásia)"])},
      "phoneAsia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+66 020260620 (Asia – Tailandia)"])},
      "phoneSingapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1415 (Ásia - Singapura)"])},
      "phoneChina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+86 400 8427 539 (Ásia - Região da China)"])},
      "phoneVietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+84 28 4458 2674 (Ásia - Vietnã)"])},
      "phoneKorea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+82 0234837246 (Asia – Corea)"])},
      "emailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address:"])},
      "emailAccountManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pt.sales", "@", "dooprime.com（Account Manager）"])},
      "emailTechnicalSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pt.support", "@", "dooprime.com（Technical Support）"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.facebook.com/DooPrime/"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.instagram.com/dooprime/"])},
      "linkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGv8ApVYNNOdwAAAXzj1XZwLGl13ZbnzGUihzUOn48chZef_12RQo0ClAQOSm8OMVcKrjF6Wocn811XJ-2Ue--xfddrLZuMOPbzwSyfbA3hWMaSC_pRHI_r2rXwLnxk9fApfv0=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fdoo-prime-limited"])},
      "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://twitter.com/DooPrime"])}
    },
    "compliance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformidade"])},
      "detection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["(Detectámos que o utilizador pode ser proveniente de uma jurisdição onde não prestamos serviços: ", _interpolate(_list(0)), ". Os seus direitos não serão protegidos pelas leis e regulamentos da jurisdição em que se encontra. O utilizador será regido pelas leis de 'a República de Vanuatu' e reconhece, pelo presente, a conformidade com as leis de 'a República de Vanuatu'.)"])},
      "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leia atentamente o seguinte Termo de Conformidade."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is owned and operated by Doo Prime Vanuatu Limited and Doo Prime Limited."])},
      "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited é uma empresa com sede em Govant Building, Po Box 1276, Port Vila, Vanuatu e o número de registro da empresa é 700238. Doo Prime Vanuatu Limited é um revendedor financeiro licenciado, autorizado e regulamentado pelos Serviços Financeiros de Vanuatu Comissão (VU FSC), e o número regulamentar é 700238."])},
      "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Limited é uma empresa com sede em Fifth Floor, Zephyr House, 122 Mary Street, George Town, P.O. Box 31493, Grand Cayman KY1-1206, Ilhas Cayman e o número de registro da empresa é SI-334856."])},
      "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se puede acceder al sitio web globalmente y no es específico para ningún cliente en ninguna jurisdicción. Esto se debe principalmente a la comodidad de la visualización centralizada y la comparación de información. Sus derechos y obligaciones reales se determinarán en función de la entidad y la jurisdicción que elija para ser regulada."])},
      "description6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você deve ter pelo menos 18 anos para poder acessar os produtos e serviços do nosso site. Ao acessar este site, você confirma ter maioridade e capacidade plena."])},
      "description7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pode haver leis e regulamentos locais que proíbem ou limitam seus direitos a acessar, baixar, distribuir, disseminar, compartilhar ou utilizar de qualquer forma algum, ou todos os documentos e informações publicadas neste site."])}
    },
    "tab": {
      "productsRiskDisclosureLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termo de divulgação de risco para Produtos de investimento de alto risco"])},
      "productsRiskDisclosureItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Títulos, futuros, CFDs e outros produtos financeiros envolvem altos riscos devido à oscilação no valor e nos preços dos instrumentos financeiros envolvidos. Devido aos movimentos adversos e imprevisíveis do mercado, grandes perdas excedendo seu investimento inicial podem ocorrer em um curto período."])},
      "productsRiskDisclosureItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O desempenho passado de um investimento não é uma indicação de seu desempenho futuro. Leia os documentos de conformidade disponíveis em nosso site."])},
      "productsRiskDisclosureItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifique-se que tenha entendido plenamente os riscos do respectivo instrumento financeiro antes de iniciar qualquer transação conosco. Você deve buscar aconselhamento profissional independente caso não entenda os riscos explicados aqui."])},
      "brandAndEntityNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso de marca e entidade"])},
      "brandAndEntityNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime, Doo Financial, Peter Elish, Doo Clearing, Doo Tech, Doo Consulting e Doo Exchange são nomes fantasia, marcas registradas e propriedades intelectuais da Doo Group."])},
      "brandAndEntityNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temos uma variedade de entidades licenciadas e reguladas em diferentes jurisdições para fornecer serviços financeiros relevantes. Você pode optar por estabelecer relações comerciais com qualquer uma de nossas entidades. Lembre-se que escolher uma entidade significa que suas contas, atividades e fundos de negociação serão governados e regulamentados somente por sua respectiva autoridade regulamentaria."])},
      "brandAndEntityNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não oferecemos nossos serviços a indivíduos ou empresas de certas jurisdições, incluindo, mas não se limitando a Afeganistão, Bósnia e Herzegovina, Burundi, Canadá, República Centro-Africana, Chade, Congo (Brazzaville), Congo (Kinshasa), Cuba, Chipre, Guiné Equatorial, Eritreia, Etiópia, Guiné, Guiné-Bissau, Guiana, Haiti, Hong Kong, Indonésia, Irã, Iraque, Israel, Líbano, Libéria, Líbia, Malásia, Mali, Nicarágua, Coreia do Norte, Rússia, Serra Leoa, Singapura, Somália, Sudão do Sul, Sri Lanka, Sudão, Síria, Tadjiquistão, Trinidad e Tobago, Tunísia, Turquemenistão, Emirados Árabes Unidos, Estados Unidos, Venezuela, Iémen e Zimbábue. As informações e serviços em nosso site não são aplicáveis e não serão fornecidos a países ou jurisdições onde a distribuição de informações e serviços seja contrária às respectivas leis e regulamentos locais. Visitantes das regiões mencionadas devem confirmar se sua decisão de investir em nossos serviços está de acordo com as leis e regulamentos em seu país ou jurisdição antes de utilizar nossos serviços. Reservamos o direito de variar, modificar ou interromper nossos produtos e serviços a qualquer momento."])},
      "clientNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso ao cliente"])},
      "clientNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quaisquer símbolos de negociação aqui exibidos são apenas para fins ilustrativos e não deverão ser considerados conselhos ou recomendações nossas. Quaisquer comentários, declarações, dados, informações, materiais ou materiais de terceiros (“Material”) fornecidos neste site são apenas para referência. O Material é usado somente para fins de comunicação de marketing e não contém, e não deverão ser interpretados, como aconselhamento financeiro e/ou uma recomendação de investimento para quaisquer transações. Ao passo que tomamos todas as medidas razoáveis para garantir a precisão e plenitude das informações, não fazemos nenhuma declaração ou garantia sobre o Material e não seremos responsáveis por quaisquer perdas, incluindo, mas não se limitando à perda de lucros, perda direta ou indireta ou danos por quaisquer imprecisões, ou omissões das informações fornecidas. Você deverá utilizar o Material apenas para uso pessoal e não deverá reproduzir, copiar, redistribuir e/ou licenciar o Material sem o nosso consentimento."])},
      "clientNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizamos cookies em nossos sites para personalizar a informação e a experiência exibida em nosso site de acordo com suas preferências.  Ao acessar este site, você confirma que leu e concordou com as informações acima e concordou com a nossa utilização de cookies."])},
      "clientNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nós cumprimos rigorosamente todas as leis e regulamentos aplicáveis nas jurisdições. É sua responsabilidade determinar e garantir que seus investimentos atendam aos seus requisitos. Você se compromete a fazer valer todas as consequências de suas atividades de investimento e negociação."])},
      "dooEntitiesNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso das entidades Doo"])},
      "dooEntitiesAddress1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc"])},
      "dooEntitiesAddress1Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É uma sociedade limitada com sede oficial em 5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States."])},
      "dooEntitiesAddress1Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una casa de bolsa registrada en la Comisión de Bolsa y Valores de EE. UU. (US SEC) y la Autoridad Reguladora de la Industria Financiera (US FINRA) con el número de regulación SEC: 8-41551 y CRD: 24409 respectivamente."])},
      "dooEntitiesAddress1Item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peter Elish Investments Securities é um nome comercial da Elish & Elish Inc."])},
      "dooEntitiesAddress2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited"])},
      "dooEntitiesAddress2Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É uma sociedade limitada com sede oficial em Berkeley Suite, 35 Berkeley Square, Mayfair, London, England, W1J 5BF, e o número de registro da sociedade é 10684079."])},
      "dooEntitiesAddress2Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited é uma corretora de câmbio e futuros e provedora de liquidez, autorizada e regulada pela Financial Conduct Authority (FCA), e o número regulador é 833414."])},
      "dooEntitiesAddress3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited"])},
      "dooEntitiesAddress3Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una empresa con domicilio social en Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000, y el número de empresa australiano es 100 139 820."])},
      "dooEntitiesAddress3Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited es un licenciatario de servicios financieros australiano, autorizado y regulado por la Comisión Australiana de Valores e Inversiones (ASIC), y el número reglamentario es 222650."])},
      "dooEntitiesAddress4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd"])},
      "dooEntitiesAddress4Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é uma empresa com sede em Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000, empresa australiana número 661 717 237."])},
      "dooEntitiesAddress4Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Doo Exchange AU Pty Ltd é uma provedora de serviços de câmbio e remessas, autorizada e regulamentada pelo Australian Transaction Reports and Analysis Center (AUSTRAC), e os números de autorização são IND100812107-001 e DCE100812107-001, respectivamente."])},
      "dooEntitiesAddress5Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited"])},
      "dooEntitiesAddress5Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É uma empresa com sede em 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong, e o número de registro da empresa é 2169884."])},
      "dooEntitiesAddress5Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Doo Holding Group Limited é um operador de serviços monetários licenciado, autorizado e regulamentado pelo Departamento de Alfândega e Impostos de Hong Kong (Licença de Operador de Serviços Monetários), e o número regulamentar é 18-06-02444."])},
      "dooEntitiesAddress6Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited"])},
      "dooEntitiesAddress6Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una compañía fiduciaria de Hong Kong registrada de conformidad con la Ordenanza de Fideicomisarios (Cap. 29, Leyes de Hong Kong) con la dirección del domicilio social en U28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong, y el número de registro de la empresa es 2601567."])},
      "dooEntitiesAddress6Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited es un proveedor de servicios de sociedades o fideicomisos con licencia, autorizado y regulado por Hong Kong Registro Mercantil (Fideicomiso o Licencia de Prestador de Servicios Societarios), y el número reglamentario es TC006709."])},
      "dooEntitiesAddress7Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited"])},
      "dooEntitiesAddress7Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una sociedad de responsabilidad limitada con domicilio social en Room B11, 1st Floor Providence Complex, Providence, Mahe, Seychelles con número de registro de empresa 8427202-1."])},
      "dooEntitiesAddress7Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited es un corredor de valores autorizado y está autorizado y regulado por la Autoridad de Servicios Financieros de Seychelles (FSA) con el número reglamentario SD090."])},
      "dooEntitiesAddress8Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited"])},
      "dooEntitiesAddress8Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é uma empresa com sede em Level 2, Lot 19, Lazenda Commercial Center Phase 3, 87000 FT Labuan, Malásia, número de registro da empresa LL17644."])},
      "dooEntitiesAddress8Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Doo Financial Labuan Limited está sujeita a licenças de corretagem de valores mobiliários e moedas financeiras, autorizada e regulamentada pela Labuan Financial Services Authority of Malaysia (MY Labuan FSA), e os números regulamentares são SL/23/0022 e MB/23/0108, respectivamente."])},
      "dooEntitiesAddress9Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited"])},
      "dooEntitiesAddress9Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É uma sociedade limitada com sede oficial em Lot 15 A3, 1st Floor, Cybercity, Ebene 72201, Mauritius e o número de registro é 172054."])},
      "dooEntitiesAddress9Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Doo Prime Mauritius Limited é uma distribuidora de investimentos licenciada, autorizada e regulada pela Mauritius Financial Services Commission (FSC) sob o número regulador C119023907."])},
      "dooEntitiesAddress10Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited"])},
      "dooEntitiesAddress10Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É uma sociedade limitada com sede oficial em Govant Building, Po Box 1276, Port Vila, Vanuatu, sob o número de registro 700238."])},
      "dooEntitiesAddress10Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Doo Prime Vanuatu Limited é uma distribuidora financeira licenciada, autorizada e regulada pela Vanuatu Financial Services Commission (FSC), e o número regulador é 700238."])}
    },
    "documents": {
      "ClientAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acuerdo del cliente"])},
      "AMLCTFPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política AML & CTF"])},
      "ExecutionPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de execução"])},
      "RefundPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de reembolso"])},
      "RiskAcknowledgement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconhecimento de risco"])},
      "PrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidade"])},
      "WebsiteTAndC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T&C do site"])},
      "Sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa do site"])}
    },
    "bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2023 Doo Prime. All Rights Reserved."])}
  }
}