<template>
    <div class="body">
        <div class="body-title">
            <div class="body-left">
                <p class="title" v-html="$t('body.title1')"></p>
                <p class="title-desc"> {{ $t('body.description1_1') }}
                    <br />
                    <br />
                   {{ $t('body.description1_2') }}
                </p>
            </div>
            <div class="body-right">
                <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/body/regulated.png">
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.body {
    background-color: #fff;

    .body-title {
        padding-top: 112px;
        padding-left: 136px;
        margin-bottom: 38px;
        display: flex;

        .title {
            font-size: 80px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            max-width: 853px;
        }

        .title-desc {
            font-size: 30px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            max-width: 811px;
            margin-top: 40px;
        }

        .body-left {
           max-width: 853px;
        }

        .body-right {
            max-width: 653px;
            max-height: 734px;
            margin-left: 136px;
            margin-right: 142px;

            img {
                width: 653px;
                height: 734px;
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .body {

        .body-title {
            flex-direction: column;
            padding-left: 0px;

            .title {
                margin: auto;
                padding-left: 35px;
                padding-right: 37px;
                font-size: 40px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.13;
                letter-spacing: normal;
                text-align: left;
                color: #000;

            }

            .title-desc {
                margin: auto;
                padding-left: 35px;
                padding-right: 37px;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                max-width: 600px;
                margin-top: 40px;
            }

            .body-left{
                max-width: 853px !important;
                margin:auto;
            }

            .body-right {
                margin: auto;
                padding-left: 0px;
                padding-top: 44px;

                img {
                    width: 375px;
                    height: 366px;
                }
            }
        }
    }
}
</style>