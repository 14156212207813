<template>
    <div id="footer-top">
        <div class="footer-top-wrapper container">
            <div class="section-about">
                <img class="about-logo" src="@/assets/logo-dp-simple.svg" alt="logo DooPrime" />
                <div class="contact-container phone">
                    <h4 class="contact-title" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100"
                        v-t="'footer.contact.phoneTitle'"></h4>
                    <!-- <p class="contact-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200"
                        v-t="'footer.contact.phoneEurope'"></p> -->
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300"
                        v-t="'footer.contact.phoneAsiaMain'"></p>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400"
                        v-t="'footer.contact.phoneSingapore'"></p>
                        <p class="contact-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="500"
                            v-t="'footer.contact.phoneChina'"></p>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300"
                        v-t="'footer.contact.phoneVietnam'"></p>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300"
                        v-t="'footer.contact.phoneAsia'"></p>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300"
                        v-t="'footer.contact.phoneKorea'"></p>

                </div>
                <div class="contact-container email">
                    <h4 class="contact-title" data-aos="fade-up" data-aos-duration="800" v-t="'footer.contact.emailTitle'">
                    </h4>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="100"
                        v-t="'footer.contact.emailAccountManager'"></p>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200"
                        v-t="'footer.contact.emailTechnicalSupport'"></p>
                </div>
            </div>
            <!-- <div class="section-menu">
                <div :class="`section-menu-row${menuTabActive === key ? ' active' : ''}`" v-for="(row, key) in footerMenu">
                    <h4 class="row-title" v-t="`footer.menu.${row.title}`" @click="setMenuActive(key)"></h4>
                    <div class="row-submenu-wrapper" ref="items">
                        <div v-if="row.items" class="row-submenu">
                            <a v-for="link in row.items" class="menu-link" :href="$t(`footer.menu.${link[1]}`)"
                                v-t="`footer.menu.${link[0]}`"></a>
                        </div>
                        <div v-if="row.group" class="row-submenu-1" v-for="submenu in row.group">
                            <h5 class="submenu-1-title" v-t="`footer.menu.${submenu.title}`"></h5>
                            <div v-if="submenu.items" class="row-submenu-2">
                                <a v-for="link in submenu.items" class="menu-link" :href="$t(`footer.menu.${link[1]}`)"
                                    v-t="`footer.menu.${link[0]}`"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
const footerMenu = [
    {
        title: "marketTitle",
        items: [
            ["marketSecurities", "marketSecuritiesUrl"],
            ["marketFutures", "marketFuturesUrl"],
            ["marketForex", "marketForexUrl"],
            ["marketMetals", "marketMetalsUrl"],
            ["marketCommodities", "marketCommoditiesUrl"],
            ["marketStock", "marketStockUrl"]
        ],
    },
    {
        title: "toolsTitle",
        group: [
            {
                title: "toolsPlatformTitle",
                items: [
                    ["toolsPlatformMetaTrader4", "toolsPlatformMetaTrader4Url"],
                    ["toolsPlatformMetaTrader5", "toolsPlatformMetaTrader5Url"],
                    ["toolsPlatformMT4vsMT5", "toolsPlatformMT4vsMT5Url"],
                    ["toolsPlatformDooPrimeInTrade", "toolsPlatformDooPrimeInTradeUrl"],
                    ["toolsPlatformTradingView", "toolsPlatformTradingViewUrl"],
                    ["toolsPlatformFIXAPI", "toolsPlatformFIXAPIUrl"],
                ]
            },
            {
                title: "toolsSocialTitle",
                items: [
                    ["toolsSocialOutrade", "toolsSocialOutradeUrl"],
                    ["toolsSocialMyfxbook", "toolsSocialMyfxbookUrl"],
                    ["toolsSocialFOLLOWME", "toolsSocialFOLLOWMEUrl"],
                ]
            },
            {
                title: "toolsAnalysisTitle",
                items: [
                    ["toolsAnalysisTradingCentral", "toolsAnalysisTradingCentralUrl"],
                    ["toolsAnalysisEconomicCalendar", "toolsAnalysisEconomicCalendarUrl"],
                ]
            },
            {
                title: "toolsTradingToolsTitle",
                items: [
                    ["toolsTradingToolsVPS", "toolsTradingToolsVPSUrl"],
                ]
            },
        ]
    },
    {
        title: "tradingTitle",
        group: [
            {
                title: "tradingTransactionOverviewTitle",
                items: [
                    ["tradingTransactionOverviewPricingModel", "tradingTransactionOverviewPricingModelUrl"],
                    ["tradingTransactionOverviewTechnology", "tradingTransactionOverviewTechnologyUrl"],
                    ["tradingTransactionOverviewAccountsComparison", "tradingTransactionOverviewAccountsComparisonUrl"],
                    ["tradingTransactionOverviewFlexibleFunding", "tradingTransactionOverviewFlexibleFundingUrl"],
                ]
            },
            {
                title: "tradingConditionsTitle",
                items: [
                    ["tradingConditionContractSpecifications", "tradingConditionContractSpecificationsUrl"],
                    ["tradingConditionTradingCalendar", "tradingConditionTradingCalendarUrl"],
                    ["tradingConditionLeverage", "tradingConditionLeverageUrl"],
                    ["tradingConditionOvernightInterest", "tradingConditionOvernightInterestUrl"],
                    ["tradingConditionDividend", "tradingConditionDividendUrl"],
                ]
            },
        ]
    },
    {
        title: "partnershipTitle",
        group: [
            {
                title: "partnershipPromotionTitle",
                items: [
                    ["partnershipPromotionPointsMall", "partnershipPromotionPointsMallUrl"],
                    ["partnershipPromotionDooTraderTradingCompetition", "partnershipPromotionDooTraderTradingCompetitionUrl"],
                ]
            },
            {
                title: "partnershipPartnersTitle",
                items: [
                    ["partnershipPartnersIntroducingBrokers", "partnershipPartnersIntroducingBrokersUrl"]
                ]
            },
        ]
    },
    {
        title: "aboutTitle",
        items: [
            ["aboutOurStory", "aboutOurStoryUrl"],
            ["aboutCorporateSocialResponsibility", "aboutCorporateSocialResponsibilityUrl"],
            ["aboutMediaCenter", "aboutMediaCenterUrl"],
            ["aboutCompliance", "aboutComplianceUrl"],
            ["aboutContactUs", "aboutContactUsUrl"],
            ["aboutCareers", "aboutCareersUrl"],
            ["aboutHelpCentre", "aboutHelpCentreUrl"],
        ],
    },
]

export default {
    name: "FooterTop",
    methods: {
        setMenuActive(id) {
            if (this.menuTabActive === id) {
                this.menuTabActive = -1;
            } else {
                this.menuTabActive = id;
            }
        }
    },
    data() {
        return {
            footerMenu: footerMenu,
            menuTabActive: -1
        }
    }
}
</script>

<style lang="scss">
#footer-top {
    position: relative;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 58px;
    background-color: #f7f8f9;
    text-align: left;
}

@media screen and (max-width: 1024px) {
    #footer-top {
        padding: 32px 24px 24px 25px;
    }
}

#footer-top .footer-top-wrapper {
    display: flex;
}

@media screen and (max-width: 1024px) {
    #footer-top .footer-top-wrapper {
        flex-direction: column;
    }
}

#footer-top .footer-top-wrapper .section-about {
    width: 100%;
    max-width: 545px;
}

#footer-top .footer-top-wrapper .section-about .about-logo {
    margin-bottom: 87px;
}

@media screen and (max-width: 1024px) {
    #footer-top .footer-top-wrapper .section-about .about-logo {
        margin-bottom: 39px;
        width: 171px;
    }
}

#footer-top .footer-top-wrapper .section-about .contact-container {
    margin-bottom: 56px;
}

@media screen and (max-width: 1024px) {
    #footer-top .footer-top-wrapper .section-about .contact-container {
        margin-bottom: 47px;
    }
}

#footer-top .footer-top-wrapper .section-about .contact-container .contact-title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 1.71px;
    color: #000018;
    margin-bottom: 16px;
}

#footer-top .footer-top-wrapper .section-about .contact-container .contact-item {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 1.5px;
    color: #36383b;
    margin-bottom: 16px;
}

#footer-top .footer-top-wrapper .section-menu {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

@media screen and (max-width: 1024px) {
    #footer-top .footer-top-wrapper .section-menu {
        flex-wrap: wrap;
    }
}

#footer-top .footer-top-wrapper .section-menu .section-menu-row {
    overflow: hidden;
}

@media screen and (max-width: 1024px) {
    #footer-top .footer-top-wrapper .section-menu .section-menu-row {
        width: 100%;
        border-top: 1px solid #e4e4e4;
    }

    #footer-top .footer-top-wrapper .section-menu .section-menu-row:last-child {
        border-bottom: 1px solid #e4e4e4;
    }
}

#footer-top .footer-top-wrapper .section-menu .section-menu-row .row-title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #36383b;
    margin-bottom: 16px;
    position: relative;
}

@media screen and (max-width: 1024px) {
    #footer-top .footer-top-wrapper .section-menu .section-menu-row .row-title {
        padding: 16px 0;
    }

    #footer-top .footer-top-wrapper .section-menu .section-menu-row .row-title::after {
        content: url('@/assets/icon-common-plus.svg');
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

#footer-top .footer-top-wrapper .section-menu .section-menu-row .row-submenu-1:nth-child(n+3) {
    margin-top: 32px;
}

#footer-top .footer-top-wrapper .section-menu .section-menu-row .row-submenu-1 .submenu-1-title {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 1.5px;
    color: #000000;
    margin-bottom: 16px;
    padding-left: 7px;
}

#footer-top .footer-top-wrapper .section-menu .section-menu-row .row-submenu-1 .submenu-1-title::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    top: 0;
    left: 0;
    background-color: #d91d22;
}

@media screen and (max-width: 1024px) {
    #footer-top .footer-top-wrapper .section-menu .section-menu-row .row-submenu-wrapper {
        max-height: 0;
        margin-top: 0;
    }

    #footer-top .footer-top-wrapper .section-menu .section-menu-row .row-title {
        margin-bottom: 0;
    }

    #footer-top .footer-top-wrapper .section-menu .section-menu-row.active .row-submenu-wrapper {
        max-height: 100vh;
        margin-top: 16px;
    }

    #footer-top .footer-top-wrapper .section-menu .section-menu-row.active .row-title::after {
        content: url('@/assets/icon-common-minus.svg');
    }
}

#footer-top .footer-top-wrapper a {
    text-decoration: none;
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #000018;
    margin-bottom: 16px;
}

#footer-top .footer-top-wrapper a:hover {
    color: #ec3f33;
}
</style>