export default {
  "banner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nâng Tầm Trải Nghiệm Giao Dịch Của Bạn Tại Doo Prime"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giao dịch với chúng tôi và khám phá vô vàn cơ hội hấp dẫn"])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng Ký Ngay"])},
    "icon1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các Cơ Quan Quản Lý Tài Chính Quốc Tế"])},
    "icon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dịch Vụ CSKH Chuyên Nghiệp 24/7"])},
    "icon3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Độ Trễ Cực Thấp"])},
    "icon4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hệ Thống Các Sản Phẩm Giao Dịch Đa Dạng"])}
  },
  "body": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các Giấy Phép Quy Định Quốc Tế"])},
    "description1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trực thuộc tập đoàn với nhiều giấy phép quốc tế, trong đó có giấy phép quy định tài chính từ các quốc gia như Vanuatu, Mauritius, Vương quốc Anh (FCA), Hoa Kỳ (SEC, FINRA)."])},
    "description1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Những giấy phép này thể hiện cam kết của chúng tôi trong việc tuân thủ các tiêu chuẩn nghiêm ngặt trong ngành, để từ đó, mang đến quý khách hàng hệ thống các dịch vụ an toàn, chất lượng với độ tin cậy cao."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài Khoản Miễn Phí Swap"])},
    "description2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tận hưởng Giao Dịch Miễn Giảm Phí Swap với Doo Prime! Từ bây giờ, bạn có thể đầu tư vào kim loại quý, chỉ số chứng khoán, và chứng khoán Mỹ mà không cần phải lo lắng về phí qua đêm."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Công Cụ Giao Dịch & Các Lợi Thế"])},
    "description3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nền Tảng CopyTrading"])},
    "description3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép giao dịch và sinh lời tự động, tại sao không? Tất cả những gì bạn cần làm là lựa chọn theo dõi nguồn tín hiệu từ một chuyên gia phù hợp, \"tăng tốc\" với chiến lược giao dịch đỉnh cao, hoặc kiếm hoa hồng trong vai trò là người giới thiệu. Dễ dàng chuyển đổi vai trò, chia sẻ lợi nhuận, và khám phá các bí quyết giao dịch hiệu quả cùng CopyTrading!"])},
    "description3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Công Nghệ"])},
    "description3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truy cập vào môi trường giao dịch hàng đầu với nền tảng công nghệ vững chắc, từ đó, dễ dàng thâm nhập vào các thị trường tài chính trên toàn cầu. Ngoài ra, bạn còn có thể tận dụng các công cụ phân tích ưu việt như Trading Central để khám phá vô vàn những cơ hội đầu tư tiềm năng."])},
    "description3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nền Tảng Giao Dịch"])},
    "description3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chúng tôi cung cấp nhiều nền tảng giao dịch đáng tin cậy và được công nhận trên trường quốc tế như MetaTrader 4, MetaTrader 5, và Doo Prime InTrade. Đặc biệt, tất cả đều khả dụng trên nhiều thiết bị khác nhau như máy tính, web, iOS, và Android, giúp bạn sở hữu trải nghiệm giao dịch vượt trội tại Doo Prime."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sẵn sàng để đồng hành cùng một nhà môi giới uy tín?"])},
    "description4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng Ký Ngay"])}
  },
  "footer": {
    "contact": {
      "phoneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông Tin Liên Hệ:"])},
      "phoneEurope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+44 11 3733 5199（Châu Âu）"])},
      "phoneAsia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 3704 4241（Châu Á）"])},
      "phoneSingapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1415 (Châu Á - Singapore)"])},
      "phoneChina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+86 400 8427 539 (Khu vực Châu Á - Trung Quốc)"])},
      "emailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
      "emailAccountManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vn.sales", "@", "dooprime.com（Chuyên Viên Khách Hàng）"])},
      "emailTechnicalSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vn.support", "@", "dooprime.com（Chuyên Viên Kỹ Thuật）"])}
    },
    "compliance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuân Thủ"])},
      "detection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["(Chúng tôi đã phát hiện ra rằng bạn có thể đang truy cập trang web này từ khu vực tài phán: ", _interpolate(_list(0)), " nơi chúng tôi không cung cấp dịch vụ. Các quyền hợp pháp của bạn sẽ không được bảo vệ theo luật và quy định tại khu vực tài phán của bạn. Bạn sẽ chịu sự điều chỉnh của luật pháp của 'the Republic of Vanuatu', và bạn xác nhận tại đây tuân thủ luật pháp của 'the Republic of Vanuatu'.)"])},
      "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng đọc kỹ Tuyên Bố Rủi Ro dưới đây"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website này được sở hữu và điều hành bởi Doo Prime Vanuatu Limited."])},
      "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited là công ty có địa chỉ văn phòng tại Tòa nhà Govant, Po Box 1276, Port Vila, Vanuatu với mã số đăng ký doanh nghiệp là 700238. Doo Prime Vanuatu Limited là một đại lý tài chính được cấp phép, ủy quyền và quản lý bởi Uỷ ban Dịch vụ Tài chính Vanuatu (VU FSC) với mã số quy định là 700238."])},
      "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Limited là công ty có địa chỉ văn phòng được đăng ký tại Tầng 5, Toà nhà Zephyr, 122 Mary Street, George Town, P.O. Box 31493, Grand Cayman KY1-1206, Cayman Islands với mã số doanh nghiệp là SI-334856."])},
      "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang web này có thể được truy cập trên phạm vi toàn cầu và không dành riêng cho bất kỳ nhóm khách hàng tại bất kỳ khu vực tài phán cụ thể nào. Điều này chủ yếu tạo điều kiện thuận lợi cho việc hiển thị tập trung và so sánh thông tin. Các quyền và nghĩa vụ thực tế của bạn sẽ được xác định dựa trên chi nhánh và khu vực tài phán mà bạn lựa chọn và chấp nhận sự điều chỉnh."])},
      "description6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn phải cam kết rằng bạn đã đủ 18 tuổi trước khi truy cập vào hệ thống sản phẩm và dịch vụ trên trang web của chúng tôi. Bằng cách truy cập vào đây, bạn xác nhận rằng bản thân đã đủ tuổi và sở hữu năng lực hành vi dân sự đầy đủ."])},
      "description7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luật và quy định từ quốc gia của bạn có thể sẽ cấm hoặc hạn chế quyền truy cập, tải xuống, phân phối, phổ biến, chia sẻ hoặc sử dụng bất kỳ hoặc tất cả các tài liệu được công bố trên trang web này."])}
    },
    "tab": {
      "productsRiskDisclosureLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Công Khai Rủi Ro Với Những Sản Phẩm Giao Dịch Mang Tính Rủi Ro Cao"])},
      "productsRiskDisclosureItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chứng khoán, Hợp đồng tương lai, CFD và các sản phẩm tài chính khác đều mang tính rủi ro cao bởi sự biến động về giá trị và giá cả của các công cụ tài chính cơ bản. Do những biến động thị trường bất lợi và không thể đoán trước, nhà giao dịch có thể phải chịu những khoản lỗ lớn vượt quá mức đầu tư ban đầu trong một thời gian ngắn."])},
      "productsRiskDisclosureItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệu quả hoạt động trong quá khứ của một khoản đầu tư không phải là dấu hiệu cho thấy hiệu quả hoạt động của khoản đầu tư đó trong tương lai. Vui lòng đọc các nguyên tắc hiện hành trên trang web của chúng tôi."])},
      "productsRiskDisclosureItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng đảm bảo rằng bạn hiểu đầy đủ các rủi ro khi giao dịch với công cụ tài chính tương ứng trước khi tham gia vào bất kỳ giao dịch nào với chúng tôi. Bạn nên tìm kiếm lời khuyên chuyên môn độc lập nếu bạn không hiểu những rủi ro được giải thích ở đây."])},
      "brandAndEntityNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông Báo Liên Quan Đến Thương Hiệu Và Các Chi Nhánh"])},
      "brandAndEntityNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime, Doo Financial, Peter Elish, Doo Clearing, Doo Tech, Doo Consulting và Doo Exchange là các tên giao dịch, nhãn hiệu và tài sản trí tuệ của Doo Group."])},
      "brandAndEntityNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chúng tôi có nhiều tổ chức được quản lý và cấp phép trong các khu vực pháp lý khác nhau nhằm cung cấp các dịch vụ tài chính đa dạng. Bạn có thể thiết lập mối quan hệ kinh doanh với bất kỳ tổ chức nào của chúng tôi. Xin lưu ý việc lựa chọn tổ chức mang ý nghĩa rằng tài khoản giao dịch, hoạt động giao dịch và quỹ cá nhân sẽ chỉ được quản lý và điều chỉnh bởi cơ quan quản lý tương ứng."])},
      "brandAndEntityNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chúng tôi không cung cấp dịch vụ của mình cho các cá nhân hoặc công ty thuộc một số khu vực pháp lý nhất định, bao gồm nhưng không giới hạn ở Afghanistan, Bosnia và Herzegovina, Burundi, Canada, Cộng hòa Trung Phi, Chad, Congo (Brazzaville), Congo (Kinshasa), Cuba, Síp, Xích đạo Guinea, Eritrea, Ethiopia, Guinea, Guinea-Bissau, Guyana, Haiti, Hồng Kông, Indonesia, Iran, Iraq, Israel, Lebanon, Liberia, Libya, Malaysia, Mali, Nicaragua, Bắc Triều Tiên, Nga, Sierra Leone, Singapore, Somalia , Nam Sudan, Sri Lanka, Sudan, Syria, Tajikistan, Trinidad và Tobago, Tunisia, Turkmenistan, Các Tiểu vương quốc Ả Rập Thống nhất, Hoa Kỳ, Venezuela, Yemen, Zimbabwe. Thông tin và dịch vụ trên trang web của chúng tôi không được áp dụng và sẽ không được cung cấp cho quốc gia hoặc khu vực pháp lý nơi việc phân phối thông tin và dịch vụ đó trái với các đạo luật và quy định tương ứng của địa phương. Khách truy cập từ các khu vực trên nên xác nhận xem quyết định đầu tư vào dịch vụ của chúng tôi có phù hợp với các đạo luật và quy định ở quốc gia hoặc khu vực pháp lý của bạn hay không trước khi bạn sử dụng dịch vụ của chúng tôi. Chúng tôi có quyền thay đổi, sửa đổi hoặc ngừng sản phẩm và dịch vụ của mình bất kỳ lúc nào."])},
      "clientNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông Báo Đến Quý Khách Hàng"])},
      "clientNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bất kỳ ký hiệu giao dịch nào được hiển thị ở đây chỉ dành cho mục đích minh họa và sẽ không cấu thành bất kỳ lời khuyên hoặc khuyến nghị nào của chúng tôi. Mọi nhận xét, tuyên bố, dữ liệu, thông tin, tài liệu hoặc tài liệu của bên thứ ba (“Tài liệu”) được cung cấp trên trang web này chỉ nhằm mục đích tham khảo. Tài liệu chỉ được sử dụng cho mục đích truyền thông tiếp thị và không chứa, và sẽ không được hiểu là lời khuyên đầu tư và / hoặc khuyến nghị đầu tư cho bất kỳ giao dịch nào. Mặc dù chúng tôi đã nỗ lực hết sức hợp lý để đảm bảo tính chính xác và đầy đủ của thông tin, nhưng chúng tôi không đại diện và bảo đảm cho Tài liệu và sẽ không chịu trách nhiệm đối với bất kỳ tổn thất nào, bao gồm nhưng không giới hạn ở việc mất lợi nhuận, tổn thất trực tiếp hoặc gián tiếp hoặc thiệt hại đối với bất kỳ sự không chính xác và không đầy đủ nào từ thông tin được cung cấp. Bạn sẽ chỉ sử dụng Tài liệu cho mục đích cá nhân và không được sao chép, sao chép, phân phối lại và / hoặc cấp phép cho Tài liệu mà không có sự đồng ý của chúng tôi."])},
      "clientNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chúng tôi sử dụng cookie trên trang web của mình để tùy chỉnh thông tin và trải nghiệm hiển thị trên trang web dựa trên sở thích của bạn. Bằng cách truy cập vào trang web này, bạn xác nhận rằng bạn đã đọc và đồng ý với các chi tiết trên và đồng ý việc sử dụng cookie của chúng tôi."])},
      "clientNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chúng tôi tuân thủ nghiêm ngặt tất cả các luật và quy định hiện hành tại các khu vực tài phán. Bạn có trách nhiệm xác định và đảm bảo rằng khoản đầu tư của bạn đáp ứng các yêu cầu của bạn. Bạn cam kết chịu mọi hậu quả từ các hoạt động đầu tư và giao dịch của mình."])},
      "dooEntitiesNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo thực thể Doo"])},
      "dooEntitiesAddress1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc"])},
      "dooEntitiesAddress1Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là công ty TNHH có văn phòng đăng ký tại 5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, Hoa Kỳ."])},
      "dooEntitiesAddress1Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc là nhà môi giới-đại lý đã được đăng ký với Ủy ban Chứng khoán và Giao dịch Hoa Kỳ (US SEC) & Cơ quan Quản lý Công nghiệp Tài chính (FINRA) với số quy định lần lượt là SEC: 8-41551 và CRD: 24409."])},
      "dooEntitiesAddress1Item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peter Elish Investments Securities là tên giao dịch được ủy quyền bởi Elish & Elish Inc."])},
      "dooEntitiesAddress2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited"])},
      "dooEntitiesAddress2Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là công ty TNHH sở hữu văn phòng đại diện tại 208 Uxbridge Road, Shepherds Bush, London, Anh, W12 7JD với số đăng ký công ty là 10684079."])},
      "dooEntitiesAddress2Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited vừa là nhà môi giới hợp đồng tương lai và ngoại hối, vừa là nhà cung cấp thanh khoản, được ủy quyền và quản lý bởi Cơ quan quản lý tài chính (FCA) với số đăng ký là 833414."])},
      "dooEntitiesAddress3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited"])},
      "dooEntitiesAddress3Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là công ty có văn phòng đăng ký tại Tầng 4, 28-34 Phố O'Connell, Sydney NSW 2000, với mã số 100 139 820."])},
      "dooEntitiesAddress3Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited là đơn vị được cấp phép kinh doanh dịch vụ tài chính tại Úc, được quản lý bởi Ủy ban Đầu tư & Chứng khoán Úc với số quy định 222650."])},
      "dooEntitiesAddress4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth Management HK Limited"])},
      "dooEntitiesAddress4Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là công ty sở hữu địa chỉ đăng ký tại Unit E, 30/ F, Toà A, Billion Center, Số 1 đường Wang Kwong Road, Vịnh Kowloon, Kowloon, Hong Kong với số đăng ký công ty là 2507030."])},
      "dooEntitiesAddress4Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth Management HK Limited là một công ty môi giới bảo hiểm được cấp phép bởi Cơ quan Bảo hiểm Hồng Kông (HK IA) với số quy định là FB1823."])},
      "dooEntitiesAddress5Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited"])},
      "dooEntitiesAddress5Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["là một công ty có trụ sở đăng ký tại 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong và số đăng ký công ty là 2169884."])},
      "dooEntitiesAddress5Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited là nhà điều hành dịch vụ tiền được cấp phép, được ủy quyền và quản lý bởi Hải quan Hồng Kông (Giấy phép điều hành dịch vụ tiền), số giám sát là 18-06-02444."])},
      "dooEntitiesAddress6Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited"])},
      "dooEntitiesAddress6Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["là một công ty ủy thác của Hồng Kông được đăng ký theo Pháp lệnh Người được ủy thác, Chương 29 của Luật Hồng Kông, có văn phòng đăng ký tại 28/F, Tower 2, The Millennity, 98 How Ming Street, Kwun Tong, Kowloon, Hong Kong, và số đăng ký công ty của nó là 2601567."])},
      "dooEntitiesAddress6Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited là nhà cung cấp dịch vụ công ty hoặc ủy thác được cấp phép, được ủy quyền và quản lý bởi Cơ quan đăng ký công ty Hồng Kông (Giấy phép nhà cung cấp dịch vụ ủy thác hoặc công ty), số giấy phép TC006709."])},
      "dooEntitiesAddress7Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited"])},
      "dooEntitiesAddress7Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là công ty TNHH có trụ sở đăng ký tại Phòng B11, Tầng 1 Khu phức hợp Providence, Providence, Mahe, Seychelles với số đăng ký 8427202-1."])},
      "dooEntitiesAddress7Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited là một đại lý chứng khoán được cấp phép, ủy quyền và quản lý trực tiếp bởi Cơ quan Dịch vụ Tài chính Seychelles (FSA) với số quy định là SD090."])}
    },
    "documents": {
      "ClientAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thỏa Thuận Khách Hàng"])},
      "AMLCTFPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chính Sách AML & ATF"])},
      "ExecutionPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chính Sách Thực Thi"])},
      "RefundPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chính Sách Hoàn Tiền"])},
      "RiskAcknowledgement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác Nhận Rủi Ro"])},
      "PrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chính Sách Bảo Mật"])},
      "WebsiteTAndC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T&C Trang Web"])},
      "Sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sơ đồ trang web"])}
    },
    "bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang web này được sở hữu và điều hành bởi Doo Prime Vanuatu Limited (Công ty số 700238). © 2022 Doo Prime. Đã đăng ký Bản quyền."])}
  }
}