export default {
  "banner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevate Your Trading Potential with Doo Prime"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade with us and discover limitless opportunities"])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up Now"])},
    "icon1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Financial Regulators"])},
    "icon2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 Professional Support"])},
    "icon3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultra-Low Latency"])},
    "icon4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensive Range of Trading Products"])}
  },
  "body": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-regulated Group"])},
    "description1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part of a group with multiple international licenses, including financial regulatory licenses from countries like Vanuatu, Mauritius, the United Kingdom (FCA), the United States (SEC, FINRA)."])},
    "description1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These licenses demonstrate our dedication to adhering to strict industry standards and providing clients with safe, reliable, and high-quality services."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap-Free Accounts"])},
    "description2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy Swap-Free Trading and Swap Reduction with Doo Prime! Invest in precious metals, stock indices, and US stocks without worrying about overnight interest."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools & Advantages"])},
    "description3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copytrading"])},
    "description3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow, Lead Earn! Choose to follow expert signal sources, lead with your strategies, or earn as an introducer. Easily switch roles, share profits, and explore detailed trading insights."])},
    "description3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
    "description3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access top-notch trading environments with a strong technological foundation, enabling smooth penetration into global financial markets. utilize advanced analytical tools, such as Trading Central, to discover more investment opportunities."])},
    "description3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platforms"])},
    "description3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer a range of internationally recognized and reliable trading platforms, including MetaTrader 4, MetaTrader 5, and Doo Prime InTrade. All available for various devices such as desktop, web, iOS, and Android, providing enhanced trading experiences for our users."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to join a <span style=\"font-weight:bold;\">Reliable</span> and <span style=\"font-weight:bold;\">Trustworthy Broker?</span>"])},
    "description4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join now"])}
  },
  "footer": {
    "contact": {
      "phoneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts:"])},
      "phoneEurope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+44 11 3733 5199（Europe）"])},
      "phoneAsiaMain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 3704 4241（Asia）"])},
      "phoneAsia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+66 020260620 ( Asia – Thailand )"])},
      "phoneSingapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1415 (Asia - Singapore)"])},
      "phoneChina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+86 400 8427 539 (Asia - China Region)"])},
      "phoneVietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+84 28 4458 2674 (Asia - Vietnam)"])},
      "phoneKorea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+82 0234837246 (Asia – Korea)"])},
      "emailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address:"])},
      "emailAccountManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en.sales", "@", "dooprime.com（Account Manager）"])},
      "emailTechnicalSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en.support", "@", "dooprime.com（Technical Support）"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.facebook.com/DooPrime/"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.instagram.com/dooprime/"])},
      "linkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGv8ApVYNNOdwAAAXzj1XZwLGl13ZbnzGUihzUOn48chZef_12RQo0ClAQOSm8OMVcKrjF6Wocn811XJ-2Ue--xfddrLZuMOPbzwSyfbA3hWMaSC_pRHI_r2rXwLnxk9fApfv0=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fdoo-prime-limited"])},
      "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://twitter.com/DooPrime"])}
    },
    "compliance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Disclosure"])},
      "detection": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["(We have detected that you may be accessing this website from a jurisdiction: ", _interpolate(_list(0)), " where we do not provide services. Your legal rights shall not be protected under the laws and regulations of your jurisdiction. You shall be governed by the laws of 'the Republic of Vanuatu', and you hereby acknowledge in complying with the laws of 'the Republic of Vanuatu'.)"])},
      "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read the following Risk Disclosure carefully"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is owned and operated by Doo Prime Vanuatu Limited and Doo Prime Limited."])},
      "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited is a company with the registered office address at Govant Building, Po Box 1276, Port Vila, Vanuatu and the company registration number is 700238. Doo Prime Vanuatu Limited is a licensed financial dealer, authorized and regulated by the Vanuatu Financial Services Commission (VU FSC), and the regulatory number is 700238."])},
      "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Limited is a company with the registered office address at Fifth Floor, Zephyr House, 122 Mary Street, George Town, P.O. Box 31493, Grand Cayman KY1-1206, Cayman Islands and the company registration number is SI-334856."])},
      "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website can be accessed globally and is not specific to any client in any jurisdiction. This is mainly for the convenience of centralized display and comparison of information. Your actual rights and obligations will be determined based on the entity and jurisdiction that you choose to be regulated."])},
      "description6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must be of at least 18 years old before you can access our website products and services. By accessing our website, you confirm you are an individual of full age and capacity."])},
      "description7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There may be local laws and regulations which prohibit or limit your rights to access, download, distribute, disseminate, share or otherwise use any or all of the documents and information published on this website."])}
    },
    "tab": {
      "productsRiskDisclosureLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High Risk Trading Products Risk Disclosure"])},
      "productsRiskDisclosureItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securities, Futures, CFDs and other financial products involve high risks due to the fluctuation in the value and prices of the underlying financial instruments. Due to the adverse and unpredictable market movements, large losses exceeding your initial investment could incur within a short period of time."])},
      "productsRiskDisclosureItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past performance of an investment is not an indication of its performance in the future. Please read the compliance documents available on our website."])},
      "productsRiskDisclosureItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure you fully understand the risks of trading with the respective financial instrument before engaging in any transactions with us. You should seek independent professional advice if you do not understand the risks explained herein."])},
      "brandAndEntityNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand and Entity Notice"])},
      "brandAndEntityNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime, Doo Financial, Peter Elish, Doo Clearing, Doo Tech, Doo Consulting and Doo Exchange are trading names, trademarks and intellectual properties of Doo Group."])},
      "brandAndEntityNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a variety of regulated and licensed entities in different jurisdictions to provide relevant financial services. You can choose to establish business relationships with any of our entities. Please bear in mind that the entity chosen meant that your trading accounts, trading activities and funds shall be governed and regulated by its respective regulatory authority only."])},
      "brandAndEntityNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not offer our services to individuals or companies of certain jurisdictions, including but not limited to Afghanistan, Azerbaijan, Bosnia and Herzegovina, Burundi, Cameroon, Canada, Central African Republic, Chad, Congo (Brazzaville), Congo (Kinshasa), Cuba, Equatorial Guinea, Eritrea, Ethiopia, Guinea-Bissau, Guyana, Haiti, Hong Kong, Indonesia, Iran , Iraq, Israel, Lebanon, Libya, Malaysia, Mali, Nicaragua, North Korea, Singapore, Somalia, South Sudan, Sri Lanka, Sudan, Syria, Tajikistan, Trinidad and Tobago, Tunisia, Turkmenistan, United Arab Emirates, United States, Venezuela, Yemen, Zimbabwe. The information and services on our website are not applicable and shall not be provided to country or jurisdiction where such distribution of information and services are contrary to the respective local statutes and regulations. Visitors from the above regions should confirm whether your decision to invest in our services is in accordance with the statutes and regulations in your country or jurisdiction before you use our services. We reserve our rights to vary, amend or discontinue our products and services at any time."])},
      "clientNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Notice"])},
      "clientNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any trading symbols displayed herein are for illustrative purposes only and shall not constitute any advice or recommendation by us. Any comments, statements, data, information, material or third party material (“Material”) provided on this website are for reference purposes only. The Material is used solely for the purposes of marketing communication and does not contain, and shall not be construed as investment advice and/or an investment recommendation for any transactions. While we took all reasonable efforts to ensure the accuracy and completeness of the information, we make no representations and warranties to the Material and shall not be liable for any loss, including but not limited to loss of profit, direct or indirect loss or damages for any inaccuracies and incompleteness from the information provided. You shall only use the Material for personal use and shall not reproduce, copy, redistribute and/or license the Material without our consent."])},
      "clientNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies on our websites to customize the information and experience displayed on our website according to your preferences. By accessing this website, you acknowledge that you have read and agreed to the details above and agreed to our use of cookies."])},
      "clientNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We strictly comply with all applicable laws and regulations in jurisdictions. It is your responsibility to determine and ensure that your investment meets your requirements. You undertake to bear all the consequences of your investment and trading activities."])},
      "dooEntitiesNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Entities Notice"])},
      "dooEntitiesAddress1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc"])},
      "dooEntitiesAddress1Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at 5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States."])},
      "dooEntitiesAddress1Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc is a broker-dealer, authorized and regulated by the United States Securities and Exchange Commission (US SEC) and Financial Industry Regulatory Authority (US FINRA) with the regulation number SEC: 8-41551 and CRD: 24409 respectively."])},
      "dooEntitiesAddress1Item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peter Elish Investments Securities is an authorized trading name by Elish & Elish Inc."])},
      "dooEntitiesAddress2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited"])},
      "dooEntitiesAddress2Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at Berkeley Suite, 35 Berkeley Square, Mayfair, London, England, W1J 5BF, and the company  registration number is 10684079."])},
      "dooEntitiesAddress2Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited is a futures and forex broker and liquidity provider, authorized and regulated by the United Kingdom Financial Conduct Authority (UK FCA), and the regulatory number is 833414."])},
      "dooEntitiesAddress3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited"])},
      "dooEntitiesAddress3Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000, and the Australian Company Number is 100 139 820."])},
      "dooEntitiesAddress3Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited is an Australian financial services licensee, authorized and regulated by the Australian Securities & Investments Commission (ASIC), and the regulatory number is 222650."])},
      "dooEntitiesAddress4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd"])},
      "dooEntitiesAddress4Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000, and the Australian Company Number is 661 717 237."])},
      "dooEntitiesAddress4Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Exchange AU Pty Ltd is a remittance and digital currency exchange services providers, authorized and regulated by the Australian Transaction Reports and Analysis Centre (AUSTRAC), and the authorizations number are IND100812107-001 and DCE100812107-001 respectively."])},
      "dooEntitiesAddress5Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited"])},
      "dooEntitiesAddress5Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong, and the company registration number is 2169884."])},
      "dooEntitiesAddress5Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited is a licensed money service operator, authorized and regulated by the Hong Kong Customs and Excise Department (Money Service Operator Licence), and regulatory number is 18-06-02444."])},
      "dooEntitiesAddress6Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited"])},
      "dooEntitiesAddress6Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a Hong Kong trust company registered pursuant to the Trustee Ordinance (Cap. 29, Laws of Hong Kong) with the registered office address at Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong, and the company registration number is 2601567."])},
      "dooEntitiesAddress6Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited is a licensed trust or company service provider, authorized and regulated by the Hong Kong Companies Registry (Trust or Company Service Provider License), and the regulatory number is TC006709."])},
      "dooEntitiesAddress7Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited"])},
      "dooEntitiesAddress7Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at Room B11, 1st Floor Providence Complex, Providence, Mahe, Seychelles and the company registration number is 8427202-1."])},
      "dooEntitiesAddress7Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited is a licensed securities dealer, authorized and regulated by the Seychelles Financial Services Authority (SC FSA), and the regulatory number is SD090."])},
      "dooEntitiesAddress8Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited"])},
      "dooEntitiesAddress8Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at Level 2, Lot 19, Lazenda Commercial Centre Phase 3, 87000 FT Labuan, Malaysia, and the company registration number is LL17644."])},
      "dooEntitiesAddress8Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Labuan Limited is a securities and money-broking business licensee, authorized and regulated by the Malaysia Labuan Financial Services Authority (MY Labuan FSA), and the license number are SL/23/0022 and MB/23/0108 respectively."])},
      "dooEntitiesAddress9Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited"])},
      "dooEntitiesAddress9Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at The Cyberati Lounge, Ground Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebene, Republic of Mauritius and the company registration number is 172054."])},
      "dooEntitiesAddress9Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited is a licensed investment dealer, authorized and regulated by the Mauritius Financial Services Commission (MU FSC), and the regulatory number is C119023907."])},
      "dooEntitiesAddress10Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited"])},
      "dooEntitiesAddress10Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office address at Govant Building, Po Box 1276, Port Vila, Vanuatu and the company registration number is 700238."])},
      "dooEntitiesAddress10Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited is a licensed financial dealer, authorized and regulated by the Vanuatu Financial Services Commission (VU FSC), and the regulatory number is 700238."])}
    },
    "documents": {
      "ClientAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Agreement"])},
      "AMLCTFPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML & CTF Policy"])},
      "ExecutionPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution Policy"])},
      "RefundPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Policy"])},
      "RiskAcknowledgement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Acknowledgement"])},
      "PrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
      "WebsiteTAndC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website T&C"])},
      "Sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitemap"])}
    },
    "bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2023 Doo Prime. All Rights Reserved."])}
  }
}