<template>
    <div class="body">
        <div class="body-title">
            <p v-html="$t('body.title3')" class="title">

            </p>
            <div class="icon-part">
                <div class="container">
                    <div class="icon">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/body/icon-copytrading.svg"
                            style="margin-top:35px;">
                    </div>
                    <p v-html="$t('body.description3_1')" class="icon-title">
                        
                    </p>
                    <p v-html="$t('body.description3_2')" class="icon-desc">
                        
                    </p>
                </div>
                <div class="container">
                    <div class="icon">
                        <img
                            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/body/icon-technology.svg">
                    </div>
                    <p v-html="$t('body.description3_3')" class="icon-title">
                        
                    </p>
                    <p v-html="$t('body.description3_4')" class="icon-desc">

                    </p>
                </div>
                <div class="container">
                    <div class="icon">
                        <img
                            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib-marketing/body/icon-platfroms.svg">
                    </div>
                    <p v-html="$t('body.description3_5')" class="icon-title">
                        
                    </p>
                    <p v-html="$t('body.description3_6')" class="icon-desc">
              
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.body {

    .body-title {
        margin-left: 136px;

        .title {
            font-size: 80px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            margin-top: 73px;
            margin-bottom: 89px;
        }

        .icon-part {
            display: flex;
            flex-direction: row;

            .icon {
                display: flex;
                width: 216px;
                height: 216px;
                background-color: #fbfbfd;
                border-radius: 50%;
                justify-content: center;
                align-items: center;

                img {
                    width: 136px;
                    height: 136px;
                    margin-bottom: 27px;
                }
            }

            .icon-title {
                font-size: 30px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                margin-bottom: 16px;
            }

            .icon-desc {
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                max-width: 540px;
                margin-bottom: 46px;
                margin-right: 16px;
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .body {

        .body-title {
            margin: auto;
            padding-left:35px;

            .title {
                font-size: 40px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.13;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                padding-top: 73px;
                max-width: 600px;
                width: 100%;
                padding-bottom: 89px;
                margin: auto;
            }

            .icon-part {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: auto;
                max-width: 600px;
                align-items: flex-start;
                padding-right:40px;

                .container {}

                .icon {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;
                    margin-bottom: 15px;
                    width: 121px;
                    height: 121px;

                    img {
                        width: 76px;
                        height: 76px;
                        margin-bottom: 27px;
                        margin-left: 25px;
                    }
                }

                .icon-title {
                    font-size: 24px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: left;
                    color: #000;
                    margin-bottom: 16px;
                }

                .icon-desc {
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: left;
                    color: #000;
                    max-width: 600px;
                    margin-bottom: 46px;
                    margin-right: 12px;
                }
            }
        }
    }
}
</style>